import {useRef, useState} from 'react'
import {KTCard} from '../../../../../_metronic/helpers'
import {SitesListWrapper} from '../../site-management/sites-list/SitesList'
import {SiteProvider} from '../../site-management/sites-list/core/SiteProvider'
import {WaybillsListWrapper} from '../../waybill-management/waybills-list/WaybillsList'
import {Company} from '../companies-list/core/_models'
import {ESITE_QUERY_TYPE, Site} from '../../site-management/sites-list/core/_models'
import {WaybillProvider} from '../../waybill-management/waybills-list/core/WaybillProvider'
import {SiteWrapper} from '../../site-management/site-details/SiteWrapper'

export const CompanyDetails = ({company, isLoading}: any) => {
  // const {siteIdState, setSiteIdState, site, setSite} = useCompanyDetails()

  const [_company, setCompany] = useState<Company>(company)
  const [site, setSite] = useState<Site>()

  const [isCompanyLoading, setCompanyLoading] = useState<boolean>(isLoading)
  const [isSiteLoading, setSiteLoading] = useState<boolean>(false)

  const waybillSection = useRef<HTMLDivElement | null>(null)

  const scrolToWaybillSection = () => {
    if (waybillSection?.current) {
      console.log('scroll')
      window.scrollTo({
        top: waybillSection.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <SiteProvider
        company={_company}
        site={site}
        setCompany={setCompany}
        setSite={setSite}
        setCompanyLoading={setCompanyLoading}
        setSiteLoading={setSiteLoading}
        isCompanyLoading={isCompanyLoading}
        isSiteLoading={isSiteLoading}
        scrollTo={scrolToWaybillSection}
        siteQueryType={ESITE_QUERY_TYPE.SITES_BY_COMPANY}
      >
        <div className=' g-5 g-xxl-8 mb-5'>
          <KTCard>
            <div className=''>
              <div className=' pt-2 pb-1'>
                <div className=''>
                  <div className='flex-grow-1'>
                    <SitesListWrapper />
                  </div>
                </div>
              </div>
            </div>
          </KTCard>
        </div>
        <div>
          {site && <SiteWrapper />}
          <div ref={waybillSection}></div>
        </div>
      </SiteProvider>
      <div className=' g-5 g-xxl-8'>
        {site && (
          <>
            <KTCard>
              <div className=' pt-2 pb-1'>
                <div className=''>
                  <div className='flex-grow-1'>
                    <WaybillProvider
                      company={_company}
                      site={site}
                      setCompany={setCompany}
                      setSite={setSite}
                      setCompanyLoading={setCompanyLoading}
                      setSiteLoading={setSiteLoading}
                      isCompanyLoading={isCompanyLoading}
                      isSiteLoading={isSiteLoading}
                    >
                      <WaybillsListWrapper site={site} setSite={setSite} />
                    </WaybillProvider>
                  </div>
                </div>
              </div>
            </KTCard>
          </>
        )}
      </div>
    </>
  )
}
