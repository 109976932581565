const CompanyLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    // backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    // width: 'auto',
    padding: '1rem 2rem',
    // top: 'calc(15%)',
    // left: 'calc(50%)',
  }

  return (
    <div style={{...styles, textAlign: 'center'}}>
      Loading...
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </div>
  )
}

export {CompanyLoading}
