import {API} from 'aws-amplify'
import {UserModel} from '../../../../auth'

export const getCompaniesForOrg = async (currentUser: UserModel, query: string) => {
  return API.get(
    'app_base',
    `/organization/${currentUser.currentOrganization}/companyName?${query}`,
    {}
  )
}

export const get = async (id: string, currentUser: UserModel) => {
  const encodedUrl = encodeURIComponent(
    `/organization/${currentUser.currentOrganization}/company/${id}`
  )
  return API.get('app_base', encodedUrl, {})
}

export const create = async (currentUser: UserModel, company: any) => {
  return API.post('app_base', `/organization/${currentUser.currentOrganization}/company`, {
    body: company,
  })
}

export const update = async (currentUser: UserModel, company: any) => {
  return API.put(
    'app_base',
    `/organization/${currentUser.currentOrganization}/company/${company.id}`,
    {
      body: company,
    }
  )
}

export const del = async (currentUser: UserModel, companyId: any) => {
  return API.del(
    'app_base',
    `/organization/${currentUser.currentOrganization}/company/${companyId}`,
    {}
  )
}
