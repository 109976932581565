import {useFormik} from 'formik'
import React, {useState} from 'react'
import * as Yup from 'yup'
import {EBATCH_STATUS, QUERIES, isNotEmpty} from '../../../../../../_metronic/helpers'
import {useSiteListView} from '../../sites-list/core/SiteListViewProvider'
import {SiteBatchEditFormInput} from './SiteBatchEditFormInput'
import {useQueryClient} from 'react-query'
import {useAuth} from '../../../../auth'
import {processBatch} from '../../sites-list/core/_requests'
import {Batch} from '../../sites-list/core/_models'

export const SiteBatchModalForm = () => {
  const [batchForSubmit] = useState<Batch>({
    waybillPrintCount: 1,
    labelPrintCount: 1,
  })
  const isBatchLoading = false
  const {setProcessBatch} = useSiteListView()
  const queryClient = useQueryClient()
  const {currentUser} = useAuth()

  const batchForSubmitSchema = Yup.object().shape({
    serviceType: Yup.string().required('Service Type is required'),
    waybillPrintCount: Yup.number().required('Waybill Print Count is required'),
    labelPrintCount: Yup.number().required('Label Print Count is required'),
  })

  const formik = useFormik({
    initialValues: batchForSubmit,
    validationSchema: batchForSubmitSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        queryClient.setQueryData([`${QUERIES.BATCH_STATUS}`], EBATCH_STATUS.STATUS_PROCESSING_BATCH)
        processBatch(currentUser, values)
        setSubmitting(true)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      // refetch()
    }
    setProcessBatch(false)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-6'></div>
          <div className='col-md-6'></div>

          <div className='fv-row mb-2'>
            <label className='required fw-bold fs-7 mb-2'>Service Type</label>

            <select
              className='form-select form-select-solid form-select-lg fs-7'
              {...formik.getFieldProps('serviceType')}
            >
              <option value=''>Select a Service Type..</option>
              <option value='EAD'>EAD</option>
            </select>

            {formik.touched.serviceType && formik.errors.serviceType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.serviceType}</span>
                </div>
              </div>
            )}
          </div>

          <SiteBatchEditFormInput
            label='Line 1'
            name='line1'
            fieldProps={formik.getFieldProps('line1')}
            touchedProp={formik.touched.line1}
            errorsProp={formik.errors.line1}
            isSubmitting={formik.isSubmitting}
            isLoading={isBatchLoading}
            disabled={false}
          />
          <SiteBatchEditFormInput
            label='Line 2'
            name='line2'
            fieldProps={formik.getFieldProps('line2')}
            touchedProp={formik.touched.line2}
            errorsProp={formik.errors.line2}
            isSubmitting={formik.isSubmitting}
            isLoading={isBatchLoading}
            disabled={false}
          />
          <SiteBatchEditFormInput
            label='Line 3'
            name='line3'
            fieldProps={formik.getFieldProps('line3')}
            touchedProp={formik.touched.line3}
            errorsProp={formik.errors.line3}
            isSubmitting={formik.isSubmitting}
            isLoading={isBatchLoading}
            disabled={false}
          />
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <SiteBatchEditFormInput
              label='Number of Waybills to Print'
              name='waybillPrintCount'
              fieldProps={formik.getFieldProps('waybillPrintCount')}
              touchedProp={formik.touched.waybillPrintCount}
              errorsProp={formik.errors.waybillPrintCount}
              isSubmitting={formik.isSubmitting}
              isLoading={isBatchLoading}
              disabled={false}
            />
          </div>
          <div className='col-md-6'>
            <SiteBatchEditFormInput
              label='Number of Labels to Print'
              name='labelPrintCount'
              fieldProps={formik.getFieldProps('labelPrintCount')}
              touchedProp={formik.touched.labelPrintCount}
              errorsProp={formik.errors.labelPrintCount}
              isSubmitting={formik.isSubmitting}
              isLoading={isBatchLoading}
              disabled={false}
            />
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isBatchLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isBatchLoading ||
              formik.isSubmitting ||
              !(formik.isValid && formik.dirty) ||
              !formik.touched
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isBatchLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {/* {(formik.isSubmitting || isWaybillLoading) && <WaybillsListLoading />} */}
    </>
  )
}
