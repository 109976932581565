import React, {useEffect} from 'react'
import {KTSVG, initialQueryState, useDebounce} from '../../../../../../../_metronic/helpers'
import {useWaybillQueryRequest} from '../../core/WaybillQueryRequestProvider'

export const WaybillsListSearchComponent = () => {
  const {updateState, searchTerm, setSearchTerm} = useWaybillQueryRequest()

  const debouncedSearchTerm = useDebounce(searchTerm.toUpperCase(), 500)

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    // <div>
    //   <div className='badge badge-light-success fw-bolder me-auto fs-2 py-2'>&nbsp;WAYBILLS</div>
    // </div>
    <>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />

        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search waybills'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </>
  )
}
