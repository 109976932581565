/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {useWaybillListView} from '../../core/WaybillListViewProvider'
import {Waybill} from '../../core/_models'
// import {deleteUser} from '../../core/_requests'

type Props = {
  waybill: Waybill
}

const WaybillActionsCell: FC<Props> = ({waybill}) => {
  const {setWaybillsForPrint} = useWaybillListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    if (setWaybillsForPrint) {
      setWaybillsForPrint([waybill])
    }
  }

  // const deleteItem = useMutation(() => deleteUser(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })

  return (
    <>
      <a
        href='#'
        className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 me-1'
        onClick={openEditModal}
      >
        Print
      </a>
      {/* <a
        href='#'
        className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 px-2'
      >
        Waybill
      </a> */}
    </>
  )
}

export {WaybillActionsCell}
