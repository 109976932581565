import {FC, createContext, useContext} from 'react'
import {Company} from '../../../company-management/companies-list/core/_models'
import {Site, ESITE_QUERY_TYPE} from './_models'
import {WithChildren} from '../../../../../../_metronic/helpers'

type SiteContextProps = {
  company: Company
  site?: Site
  setCompany: (company: Company) => void
  setSite: (site?: Site) => void
  setCompanyLoading: (isCompanyLoading: boolean) => void
  setSiteLoading: (isSiteLoading: boolean) => void
  isCompanyLoading: boolean
  isSiteLoading?: boolean
  scrollTo: () => void
  siteQueryType: ESITE_QUERY_TYPE
}

export const initialSiteContext: SiteContextProps = {
  company: {} as Company,
  site: {} as Site,
  setCompany: () => {},
  setSite: () => {},
  setCompanyLoading: () => {},
  setSiteLoading: () => {},
  isCompanyLoading: false,
  isSiteLoading: false,
  scrollTo: () => {},
  siteQueryType: ESITE_QUERY_TYPE.SITES_BY_COMPANY,
}

interface SiteProviderProps extends WithChildren {
  company: Company
  site?: Site
  setCompany: (company: Company) => void
  setSite: (site?: Site) => void
  setCompanyLoading: (isCompanyLoading: boolean) => void
  setSiteLoading: (isSiteLoading: boolean) => void
  isCompanyLoading: boolean
  isSiteLoading?: boolean
  scrollTo: () => void
  siteQueryType: ESITE_QUERY_TYPE
}

const SiteContext = createContext<SiteContextProps>(initialSiteContext)

const SiteProvider: FC<SiteProviderProps> = ({
  children,
  company,
  site,
  setCompany,
  setSite,
  setCompanyLoading,
  setSiteLoading,
  isCompanyLoading,
  isSiteLoading,
  scrollTo,
  siteQueryType,
}) => {
  return (
    <SiteContext.Provider
      value={{
        company,
        site,
        setCompany,
        setSite,
        setCompanyLoading,
        setSiteLoading,
        isCompanyLoading,
        isSiteLoading,
        scrollTo,
        siteQueryType,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}

const useSiteProvider = () => useContext(SiteContext)

export {SiteProvider, useSiteProvider}
