import React, {FC} from 'react'
import {Site} from '../sites-list/core/_models'
import {SiteHeader} from './SiteHeader'
import {SiteDetails} from './SiteDetails'

export const SiteWrapper = () => {
  return (
    <>
      <SiteHeader />
      <SiteDetails />
    </>
  )
}
