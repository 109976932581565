import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTCard, isNotEmpty} from '../../../../../../_metronic/helpers'
import {useAuth} from '../../../../auth'
import {useWaybillListView} from '../core/WaybillListViewProvider'
import {WaybillPrintFormInput} from './WaybillPrintFormInput'
import {Waybill, initialWaybill} from '../core/_models'
import {useWaybillProvider} from '../core/WaybillProvider'
import {printWaybill} from '../core/_requests'
import {useWaybillQueryResponse} from '../core/WaybillQueryResponseProvider'
import {WaybillsListLoading} from '../components/loading/WaybillsLoading'
import CourierText from './CourierText'

type Props = {
  isWaybillLoading: boolean
  waybills: Waybill[]
}

type WaybillsForPrint = {
  waybills: Waybill[]
  waybillPrintCount: number
  labelPrintCount: number
}

const WaybillPrintModalForm: FC<Props> = ({waybills, isWaybillLoading}) => {
  const {setWaybillsForPrint, selected, clearSelected} = useWaybillListView()
  const {refetch} = useWaybillQueryResponse()
  const {currentUser} = useAuth()
  const [waybillIndex, setWaybillIndex] = useState<number>(0)

  const [waybillsForPrint] = useState<WaybillsForPrint>({
    waybills,
    waybillPrintCount: 1,
    labelPrintCount: 1,
  })

  const nextWaybill = () => {
    if (waybillIndex < waybillsForPrint.waybills.length - 1) {
      setWaybillIndex((waybillIndex) => waybillIndex + 1)
    }
  }

  const previousWaybill = () => {
    if (waybillIndex > 0) {
      setWaybillIndex((waybillIndex) => waybillIndex - 1)
    }
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setWaybillsForPrint(undefined)
  }

  const printWaybillSchema = Yup.object().shape({
    waybillPrintCount: Yup.number().min(1).max(20).required('Print count required'),
    labelPrintCount: Yup.number().min(1).max(20).required('Print count required'),
  })

  const formik = useFormik({
    initialValues: waybillsForPrint,
    validationSchema: printWaybillSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await printWaybill(
          currentUser,
          waybillsForPrint.waybills,
          values.waybillPrintCount,
          values.labelPrintCount
        )
      } catch (ex) {
        console.error(ex)
      } finally {
        clearSelected()
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const generateWaybillContent = () => {
    const waybillForPrint = waybillsForPrint.waybills[waybillIndex]
    const waybillContent = `\t\t\t\tDelivery No.: ${waybillForPrint.waybillNumber}\nDate: ${
      waybillForPrint?.createDate
    } \nAtt: ${waybillForPrint.contactPerson} \nCompany: ${waybillForPrint.siteName} \n${
      waybillForPrint.address1 ? waybillForPrint.address1 + '\n' : ''
    }${waybillForPrint.address2 ? waybillForPrint.address2 + '\n' : ''}${
      waybillForPrint.address3 ? waybillForPrint.address3 + '\n' : ''
    }${waybillForPrint.address4 ? waybillForPrint.address4 + '\n' : ''}${
      waybillForPrint.postCode ? waybillForPrint.postCode : ''
    } \nTel: ${waybillForPrint.contactNumber + '\n'}Line1: ${
      waybillForPrint.line1 ? waybillForPrint.line1 + '\n' : '\n'
    }Line2: ${waybillForPrint.line2 ? waybillForPrint.line2 + '\n' : '\n'}Line3: ${
      waybillForPrint.line3 ? waybillForPrint.line3 + '\n' : '\n'
    }`
    return waybillContent
  }

  const generateLabelContent = () => {
    const waybillForPrint = waybillsForPrint.waybills[waybillIndex]
    const labelContent = `\t\t\t\t${waybillForPrint.waybillNumber}\nTo: ${
      waybillForPrint.siteName
    }\n${waybillForPrint.address1 ? waybillForPrint.address1 + '\n' : ''}${
      waybillForPrint.address2 ? waybillForPrint.address2 + '\n' : ''
    }${waybillForPrint.address3 ? waybillForPrint.address3 + '\n' : ''}${
      waybillForPrint.address4 ? waybillForPrint.address4 + '\n' : ''
    }${waybillForPrint.postCode ? waybillForPrint.postCode : ''} \n\t\t\t\t${
      waybillForPrint.contactPerson + '\n'
    }${waybillForPrint.contactNumber + '\n'}`
    return labelContent
  }

  return (
    <>
      {selected.length > 1 && (
        <>
          <div className='card-header border-0 pt-6'>
            <div className=''>
              <button
                onClick={() => previousWaybill()}
                className='btn btn-sm btn-light-success me-3'
                disabled={formik.isSubmitting || isWaybillLoading}
              >
                Prev
              </button>
            </div>
            <div className='' data-kt-user-table-toolbar='base'>
              <button
                onClick={() => nextWaybill()}
                className='btn btn-sm btn-light-success me-3'
                disabled={formik.isSubmitting || isWaybillLoading}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <KTCard>
            <div className=''>
              <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                Waybill{' '}
                {selected.length > 1 && (
                  <span className='badge badge-light-success fs-5 fw-bolder mb-2'>
                    {waybillIndex + 1} of {selected.length}
                  </span>
                )}
              </div>
              <CourierText content={generateWaybillContent()} />
            </div>
          </KTCard>

          <KTCard>
            <div className=''>
              <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>Label</div>
              <CourierText content={generateLabelContent()} />
            </div>
          </KTCard>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <WaybillPrintFormInput
              label='Number of Waybills to Print'
              name='waybillPrintCount'
              fieldProps={formik.getFieldProps('waybillPrintCount')}
              touchedProp={formik.touched.waybillPrintCount}
              errorsProp={formik.errors.waybillPrintCount}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={false}
            />
          </div>
          <div className='col-md-6'>
            <WaybillPrintFormInput
              label='Number of Labels to Print'
              name='labelPrintCount'
              fieldProps={formik.getFieldProps('labelPrintCount')}
              touchedProp={formik.touched.labelPrintCount}
              errorsProp={formik.errors.labelPrintCount}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={false}
            />
          </div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isWaybillLoading}
          >
            Discard
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isWaybillLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isWaybillLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isWaybillLoading) && <WaybillsListLoading />}
    </>
  )
}

export {WaybillPrintModalForm}
