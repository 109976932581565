import {UserModel} from '../../auth'
import {getBatchStatus as getBatchStatusRequest} from './BatchHelpers.amplify'

export const getBatchStatus = (currentUser: UserModel | undefined): Promise<String | undefined> => {
  if (currentUser) {
    return getBatchStatusRequest(currentUser).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}
