import {createContext, useContext} from 'react'
import {Company} from '../../../company-management/companies-list/core/_models'
import {Site} from '../../../site-management/sites-list/core/_models'
import {WithChildren} from '../../../../../../_metronic/helpers'

type WaybillContextProps = {
  company: Company
  site?: Site
  setCompany: (company: Company) => void
  setSite: (site?: Site) => void
  setCompanyLoading: (isCompanyLoading: boolean) => void
  setSiteLoading: (isSiteLoading: boolean) => void
  isCompanyLoading: boolean
  isSiteLoading?: boolean
}

export const initialWaybillContext: WaybillContextProps = {
  company: {} as Company,
  site: {} as Site,
  setCompany: () => {},
  setSite: () => {},
  setCompanyLoading: () => {},
  setSiteLoading: () => {},
  isCompanyLoading: false,
  isSiteLoading: false,
}

interface WaybillProviderProps extends WithChildren {
  company: Company
  site?: Site
  setCompany: (company: Company) => void
  setSite: (site?: Site) => void
  setCompanyLoading: (isCompanyLoading: boolean) => void
  setSiteLoading: (isSiteLoading: boolean) => void
  isCompanyLoading: boolean
  isSiteLoading?: boolean
}

const WaybillContext = createContext<WaybillContextProps>(initialWaybillContext)

const WaybillProvider = ({
  children,
  company,
  site,
  setCompany,
  setSite,
  setCompanyLoading,
  setSiteLoading,
  isCompanyLoading,
  isSiteLoading,
}: WaybillProviderProps) => {
  return (
    <WaybillContext.Provider
      value={{
        company,
        site,
        setCompany,
        setSite,
        setCompanyLoading,
        setSiteLoading,
        isCompanyLoading,
        isSiteLoading,
      }}
    >
      {children}
    </WaybillContext.Provider>
  )
}

const useWaybillProvider = () => useContext(WaybillContext)

export {WaybillProvider, useWaybillProvider}
