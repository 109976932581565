import {DynamoDbResponse, ID} from '../../../../../../_metronic/helpers'

export type Waybill = {
  id?: ID
  companyId?: ID
  siteId?: string
  companyName?: string
  siteName?: string
  siteCode?: string
  waybillNumber?: string
  serviceType?: string
  createDate?: string
  contactNumber?: string
  contactPerson?: string
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  postCode?: string
  line1?: string
  line2?: string
  line3?: string
}

export const initialWaybill: Waybill = {
  contactPerson: '',
  contactNumber: '',
  address1: '',
  address2: '',
  address3: '',
  address4: '',
  postCode: '',
  waybillNumber: '',
  serviceType: '',
  createDate: '',
  siteName: '',
  siteCode: '',
  companyName: '',
  line1: '',
  line2: '',
  line3: '',
}

export type WaybillQueryResponse = DynamoDbResponse<Array<Waybill>>
