import React, {FC, useEffect} from 'react'
import {Waybill} from '../../core/_models'

type Props = {
  waybill: Waybill
}

export const WaybillAddressCell: FC<Props> = ({waybill}) => {
  return (
    <div>
      {waybill.address1},{waybill.address2},{waybill.address3},{waybill.address4},{waybill.postCode}
    </div>
  )
}
