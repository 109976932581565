import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SitesListWrapper} from './sites-list/SitesList'
import {SiteWrapper} from './site-details/SiteWrapper'
import {SitesBatch} from './site-batch/SitesBatch'

const companiesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Site Management',
    path: '/apps/company-management/sites',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SitesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='sites'
          element={
            <>
              <PageTitle breadcrumbs={companiesBreadcrumbs}>Sites list</PageTitle>
              <SitesListWrapper />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='batch'
          element={
            <>
              <PageTitle breadcrumbs={companiesBreadcrumbs}>Batch list</PageTitle>
              <SitesBatch />
            </>
          }
        />
      </Route>
      <Route
        path='site/:siteId'
        element={
          <>
            <PageTitle breadcrumbs={companiesBreadcrumbs}>Site details</PageTitle>
            <SiteWrapper />
          </>
        }
      ></Route>
      <Route index element={<Navigate to='/apps/site-management/sites' />} />
    </Routes>
  )
}

export default SitesPage
