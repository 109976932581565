import {FC, createContext, useContext, useEffect, useState} from 'react'
import {EBATCH_STATUS, QUERIES, WithChildren} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getBatchStatus} from './_requests'
import {useAuth} from '../../auth'

type BatchContextProps = {
  batchStatus: EBATCH_STATUS
  isBatchStatusFetching: boolean
  refetch: () => void
}

const BatchContext = createContext<BatchContextProps>({
  batchStatus: EBATCH_STATUS.STATUS_UNKNOWN,
  isBatchStatusFetching: false,
  refetch: () => {},
})

const BatchProvider: FC<WithChildren> = ({children}) => {
  const {currentUser} = useAuth()
  const [batchStatus, setBatchStatus] = useState<EBATCH_STATUS>(EBATCH_STATUS.STATUS_UNKNOWN)

  const {isFetching, refetch, data} = useQuery(
    `${QUERIES.BATCH_STATUS}`,
    () => {
      return getBatchStatus(currentUser)
    },
    {
      refetchInterval: 10000,
    }
  )

  useEffect(() => {
    if (!isFetching && data) {
      // console.log({data})
      const _batchStatus: string = data?.toString()
      setBatchStatus(EBATCH_STATUS[_batchStatus as keyof typeof EBATCH_STATUS])
    }
  }, [data, isFetching])

  return (
    <BatchContext.Provider
      value={{batchStatus, isBatchStatusFetching: isFetching, refetch: refetch}}
    >
      {children}
    </BatchContext.Provider>
  )
}

const useBatchProvider = () => useContext(BatchContext)

export {BatchProvider, useBatchProvider}
