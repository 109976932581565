import {Column, HeaderProps} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import {Site} from '../../core/_models'
import {SiteLinkCell} from './SiteLinkCell'
import {PropsWithChildren} from 'react'
import {SiteActionsCell} from './SiteActionsCell'
import {SiteSelectionCell} from './SiteSelectionCell'
import {SiteSelectionHeader} from './SiteSelectionHeader'
import {SiteBatchCell} from './SiteBatchCell'

const sitesColumns: Array<Column<Site>> = [
  {
    Header: (props) => <SiteSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <SiteSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title='Code' className='min-w-125px' />,
    accessor: 'code',
  },
  {
    Header: (props) => (
      <SiteCustomHeader tableProps={props} title='Contact Person' className='min-w-125px' />
    ),
    accessor: 'contactPerson',
  },
  {
    Header: (props) => (
      <SiteCustomHeader tableProps={props} title='Contact Number' className='min-w-125px' />
    ),
    accessor: 'contactNumber',
  },
]

const getSiteColumns = (setSite: (site: Site) => void, scrollTo: () => void) => {
  const clearSitesColumns: Array<Column<Site>> = [
    {
      Header: (props) => (
        <SiteCustomHeader tableProps={props} title='Code' className='min-w-125px' />
      ),
      accessor: 'code',
    },
    {
      Header: (props) => (
        <SiteCustomHeader tableProps={props} title='Contact Person' className='min-w-125px' />
      ),
      accessor: 'contactPerson',
    },
    {
      Header: (props) => (
        <SiteCustomHeader tableProps={props} title='Contact Number' className='min-w-125px' />
      ),
      accessor: 'contactNumber',
    },
    {
      Header: (props) => (
        <SiteCustomHeader tableProps={props} title='In Batch' className='min-w-125px' />
      ),
      id: 'inBatch',
      Cell: ({...props}) => <SiteBatchCell inBatch={props.data[props.row.index].inBatch} />,
    },
    {
      Header: (props) => (
        <SiteCustomHeader tableProps={props} title='Actions' className='min-w-100px' />
      ),
      id: 'actions',
      Cell: ({...props}) => (
        <SiteActionsCell
          id={props.data[props.row.index].id}
          inBatch={props.data[props.row.index].inBatch}
        />
      ),
    },
  ]

  const nameHeader = {
    Header: (props: PropsWithChildren<HeaderProps<Site>>) => (
      <SiteCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => (
      <SiteLinkCell
        name={props.data[props.row.index].name}
        setSite={setSite}
        site={props.data[props.row.index]}
        scrollTo={scrollTo}
      />
    ),
    // accessor: 'name',
  }

  const siteSelectionHeader = {
    Header: (props: PropsWithChildren<HeaderProps<Site>>) => (
      <SiteSelectionHeader tableProps={props} />
    ),
    id: 'selection',
    Cell: ({...props}) => <SiteSelectionCell id={props.data[props.row.index].id} />,
  }

  clearSitesColumns.unshift(nameHeader)
  clearSitesColumns.unshift(siteSelectionHeader)
  return clearSitesColumns
}

export {sitesColumns, getSiteColumns}
