/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {
  createDynamoDbInfiniteResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {Waybill} from './_models'
import {useAuth} from '../../../../auth/core/Auth'
import {useInfiniteQuery} from 'react-query'
import {useWaybillQueryRequest} from './WaybillQueryRequestProvider'
import {getWaybills, getWaybillsByCompanyId, getWaybillsBySiteId} from './_requests'

interface WaybillQueryResponseProviderProps extends WithChildren {
  siteId?: string // or number, depending on what you expect
  companyId?: string // or number, depending on what you expect
}

const WaybillQueryResponseContext =
  createDynamoDbInfiniteResponseContext<Waybill>(initialQueryResponse)

const WaybillQueryResponseProvider: FC<WaybillQueryResponseProviderProps> = ({
  children,
  siteId,
  companyId,
}) => {
  const {state} = useWaybillQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {currentUser} = useAuth()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: iresponse,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    `${QUERIES.WAYBILLS_LIST}-${siteId || companyId || 'no-company-site'}-${query}`,
    ({pageParam}) => {
      if (siteId) {
        return getWaybillsBySiteId(siteId || '', query, currentUser, pageParam)
      } else if (companyId) {
        return getWaybillsByCompanyId(companyId || '', query, currentUser, pageParam)
      } else {
        return getWaybills(query, currentUser, pageParam)
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
    }
  )

  return (
    <WaybillQueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response: iresponse,
        query,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
      }}
    >
      {children}
    </WaybillQueryResponseContext.Provider>
  )
}

const useWaybillQueryResponse = () => useContext(WaybillQueryResponseContext)

const useWaybillQueryResponseData = () => {
  const {response} = useWaybillQueryResponse()
  if (!response) {
    return {
      pages: [],
    }
  }

  return response || {}
}

const useWaybillQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useWaybillQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useWaybillQueryResponseLoading = (): boolean => {
  const {isLoading} = useWaybillQueryResponse()
  return isLoading
}

const useWaybillQueryResponseFetchingNextPage = (): boolean => {
  const {isFetchingNextPage} = useWaybillQueryResponse()
  return isFetchingNextPage
}

const useWaybillQueryResponseHasMoreData = () => {
  const {response} = useWaybillQueryResponse()
  if (!response) {
    return false
  }

  if (response.pages) {
    const pageCount = response.pages.length
    return response.pages[pageCount - 1].data?.LastEvaluatedKey ? true : false
  } else {
    return false
  }
}

const useWaybillQueryResponseFetchNextPage = (): (() => void) => {
  const {fetchNextPage} = useWaybillQueryResponse()
  return fetchNextPage
}

export {
  WaybillQueryResponseProvider,
  useWaybillQueryResponse,
  useWaybillQueryResponseData,
  useWaybillQueryResponsePagination,
  useWaybillQueryResponseLoading,
  useWaybillQueryResponseFetchNextPage,
  useWaybillQueryResponseFetchingNextPage,
  useWaybillQueryResponseHasMoreData,
}
