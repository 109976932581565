import {useState} from 'react'
import {useSiteListView} from '../../core/SiteListViewProvider'
import {useMutation, useQueryClient} from 'react-query'
import {addSitesToBatch, removeSitesFromBatch} from '../../core/_requests'
import {useAuth} from '../../../../../auth'
import {EBATCH_STATUS, QUERIES} from '../../../../../../../_metronic/helpers'
import {useSiteQueryResponse} from '../../core/SiteQueryResponseProvider'
import {useSiteProvider} from '../../core/SiteProvider'
import {ESITE_QUERY_TYPE} from '../../core/_models'
import {useBatchProvider} from '../../../../../batch/core/BatchProvider'

export const SitesListGrouping = () => {
  const {selected, clearSelected} = useSiteListView()
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const {query} = useSiteQueryResponse()
  const {company, siteQueryType} = useSiteProvider()
  const [isLoading, setLoading] = useState(false)
  const [isRemoving, setRemoving] = useState(false)
  const {batchStatus, isBatchStatusFetching} = useBatchProvider()

  const addSelectedItemsToBatch = useMutation(() => addSitesToBatch(currentUser, selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.BATCH_LIST}-${query}`])
      clearSelected()
      setLoading(false)
    },
  })

  const removeSelectedItemsFromBatch = useMutation(
    () => removeSitesFromBatch(currentUser, selected),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.BATCH_LIST}-${query}`])
        clearSelected()
        setRemoving(false)
      },
    }
  )

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {!(batchStatus === EBATCH_STATUS.STATUS_PROCESSING_BATCH) && (
        <div className='fw-bolder me-5'>
          <span className='me-2'>{selected.length}</span> Selected
        </div>
      )}

      {siteQueryType === ESITE_QUERY_TYPE.SITES_BY_COMPANY && selected.length <= 50 && (
        <button
          type='button'
          className='btn btn-sm btn-primary me-3'
          disabled={isLoading}
          onClick={async () => {
            setLoading(true)
            await addSelectedItemsToBatch.mutateAsync()
          }}
        >
          {!isLoading && 'Add Selected to Batch'}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Adding Selected to Batch...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      )}

      {!(batchStatus === EBATCH_STATUS.STATUS_PROCESSING_BATCH) && selected.length <= 50 && (
        <button
          type='button'
          className='btn btn-sm btn-warning'
          disabled={isRemoving}
          onClick={async () => {
            setRemoving(true)
            await removeSelectedItemsFromBatch.mutateAsync()
          }}
        >
          {!isRemoving && 'Remove Selected from Batch'}
          {isRemoving && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Removing Selected from Batch...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      )}
    </div>
  )
}
