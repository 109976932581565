import {WaybillsListToolbar} from './WaybillsListToolbar'
import {WaybillsListSearchComponent} from './WaybillsListSearchComponent'
import {WaybillsListGrouping} from './WaybillsListGrouping'
import {useWaybillListView} from '../../core/WaybillListViewProvider'

export const WaybillsListHeader = ({}) => {
  const {selected} = useWaybillListView()
  return (
    <>
      <div className='card-header border-0 pt-6'>
        <WaybillsListSearchComponent />
        {/* begin::Card toolbar */}
        <div className=''>
          {/* begin::Group actions */}
          {selected.length > 0 ? <WaybillsListGrouping /> : <WaybillsListToolbar />}
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
    </>
  )
}
