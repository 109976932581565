import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useWaybillListView} from '../../core/WaybillListViewProvider'
import {useWaybillProvider} from '../../core/WaybillProvider'

export const WaybillsListToolbar = () => {
  const {setItemIdForUpdate} = useWaybillListView()
  const openAddWaybillModal = () => {
    console.log('openAddWaybillModal')
    setItemIdForUpdate(null)
  }

  // useEffect(() => {
  //   console.log('selectedData', selectedData)
  // }, [selectedData])

  const {site} = useWaybillProvider()

  return (
    <>
      {/* <div>
        <div className='badge badge-light-success fw-bolder me-auto fs-2 py-2'>WAYBILLS</div>
      </div> */}

      {site && site.id && (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* begin::Add user */}
          <button type='button' onClick={openAddWaybillModal} className='btn btn-primary btn-sm'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Waybill
          </button>
          {/* end::Add user */}
        </div>
      )}
    </>
  )
}
