import {useQuery} from 'react-query'
// import {SiteEditModalForm} from './SiteEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
// import {useSiteListView} from '../core/SiteListViewProvider'
// import {getSiteById} from '../core/_requests'
import {useAuth} from '../../../../auth'
// import {SitesListLoading} from '../components/loading/SitesListLoading'
import {FC, useEffect} from 'react'
import {useWaybillListView} from '../core/WaybillListViewProvider'
import {WaybillPrintModalForm} from './WaybillPrintModalForm'

type Props = {}

const WaybillPrintModalFormWrapper: FC<Props> = () => {
  const {waybillsForPrint} = useWaybillListView()

  if (waybillsForPrint) {
    return <WaybillPrintModalForm isWaybillLoading={false} waybills={waybillsForPrint} />
  }
  return null
}

export {WaybillPrintModalFormWrapper}
