import {Company, CompanyQueryResponse} from './_models'
import {UserModel} from '../../../../auth'
import {getCompaniesForOrg, get, create, update, del} from './CompanyHelpers.amplify'
import {ID} from '../../../../../../_metronic/helpers'

const getCompanies = async (
  query: string,
  currentUser?: UserModel,
  pageParam?: any
): Promise<CompanyQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(
        pageParam.pk
      )}&last_evaluated_key_sk=${encodeURIComponent(
        pageParam.sk
      )}&last_evaluated_key_gsi1pk=${encodeURIComponent(
        pageParam.gsi1pk
      )}&last_evaluated_key_gsi1sk=${encodeURIComponent(pageParam.gsi1sk)}`
    }

    return getCompaniesForOrg(currentUser, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const getCompanyById = (
  id: ID,
  currentUser: UserModel | undefined
): Promise<Company | undefined> => {
  if (currentUser && id) {
    return get(id, currentUser).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const createCompany = (
  currentUser: UserModel | undefined,
  company: Company
): Promise<Company | undefined> => {
  if (currentUser) {
    return create(currentUser, company).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const updateCompany = (
  currentUser: UserModel | undefined,
  company: Company
): Promise<Company | undefined> => {
  if (currentUser) {
    return update(currentUser, company).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const deleteCompany = (
  currentUser: UserModel | undefined,
  companyId: ID
): Promise<Company | undefined> => {
  if (currentUser) {
    return del(currentUser, companyId).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export {getCompanies, getCompanyById, createCompany, updateCompany, deleteCompany}
