import {Column} from 'react-table'
import {Company} from '../../core/_models'
import {CompanyCustomHeader} from './CompanyCustomHeader'
import {CompanySelectionHeader} from './CompanySelectionHeader'
import {CompanySelectionCell} from './CompanySelectionCell'
import {CompanyActionsCell} from './CompanyActionsCell'
import {CompanyLinkCell} from './CompanyLinkCell'

const companiesColumns: ReadonlyArray<Column<Company>> = [
  // {
  //   Header: (props) => <CompanySelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <CompanySelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => (
      <CompanyLinkCell
        id={props.data[props.row.index].id}
        name={props.data[props.row.index].name}
      />
    ),
    // accessor: 'name',
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Account Number' className='min-w-125px' />
    ),
    accessor: 'accountNumber',
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Address 1' className='min-w-125px' />
    ),
    accessor: 'address1',
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Address 2' className='min-w-125px' />
    ),
    accessor: 'address2',
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Address 3' className='min-w-125px' />
    ),
    accessor: 'address3',
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Address 4' className='min-w-125px' />
    ),
    accessor: 'address4',
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Post Code' className='min-w-125px' />
    ),
    accessor: 'postCode',
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Actions' className='min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CompanyActionsCell id={props.data[props.row.index].id} />,
  },
]

export {companiesColumns}
