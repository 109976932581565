import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../../_metronic/helpers'

interface WaybillQueryRequestProviderProps extends WithChildren {
  siteId?: string // or number, depending on what you expect
}

const WaybillQueryRequestContext = createContext<QueryRequestContextProps>({
  ...initialQueryRequest,
  state: {page: 1, items_per_page: 10},
})

const WaybillQueryRequestProvider: FC<WaybillQueryRequestProviderProps> = ({children}) => {
  const [state, setState] = useState<QueryState>({page: 1, items_per_page: 10})
  const [searchTerm, setSearchTerm] = useState<string>('')
  // const [siteIdState, setSiteIdState] = useState<string | undefined>(siteId)
  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
    <WaybillQueryRequestContext.Provider value={{state, updateState, searchTerm, setSearchTerm}}>
      {children}
    </WaybillQueryRequestContext.Provider>
  )
}

const useWaybillQueryRequest = () => useContext(WaybillQueryRequestContext)
export {WaybillQueryRequestProvider, useWaybillQueryRequest}
