import {Column} from 'react-table'
import {WaybillCustomHeader} from './WaybillCustomHeader'
import {Waybill} from '../../core/_models'
import {WaybillActionsCell} from './WaybillActionsCell'
import {WaybillAddressCell} from './WaybillAddressCell'
import {CompanyLinkCell} from '../../../../company-management/companies-list/table/columns/CompanyLinkCell'
import {WaybillSelectionHeader} from './WaybillSelectionHeader'
import {WaybillSelectionCell} from './WaybillSelectionCell'

const waybillsColumns: Array<Column<Waybill>> = [
  {
    Header: (props) => <WaybillSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <WaybillSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Print' className='text min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <WaybillActionsCell waybill={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Waybill Number' className='min-w-125px' />
    ),
    accessor: 'waybillNumber',
  },

  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'createDate',
  },
  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Contact Person' className='min-w-125px' />
    ),
    accessor: 'contactPerson',
  },
  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Contact Number' className='min-w-125px' />
    ),
    accessor: 'contactNumber',
  },
  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <WaybillAddressCell waybill={props.data[props.row.index]} />,
  },
]

// const waybillsColumnsWithCompanyAndSite: Array<Column<Waybill>> = [
//   {
//     Header: (props) => (
//       <WaybillCustomHeader tableProps={props} title='Company' className='min-w-125px' />
//     ),
//     id: 'name',
//     Cell: ({...props}) => (
//       <CompanyLinkCell
//         id={props.data[props.row.index].companyId}
//         name={props.data[props.row.index].companyName || ''}
//       />
//     ),
//     // accessor: 'name',
//   },

//   {
//     Header: (props) => (
//       <WaybillCustomHeader tableProps={props} title='Site' className='min-w-125px' />
//     ),
//     accessor: 'siteName',
//   },
//   ...waybillsColumns,
// ]

const waybillsColumnsWithCompanyAndSite: Array<Column<Waybill>> = [...waybillsColumns]
waybillsColumnsWithCompanyAndSite.splice(
  1,
  0,
  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Company' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => (
      <CompanyLinkCell
        id={props.data[props.row.index].companyId}
        name={props.data[props.row.index].companyName || ''}
      />
    ),
    // accessor: 'name',
  },
  {
    Header: (props) => (
      <WaybillCustomHeader tableProps={props} title='Site' className='min-w-125px' />
    ),
    accessor: 'siteName',
  }
)
//  waybillsColumns.splice(
//   1,
//   0,
//   {
//     Header: (props) => (
//       <WaybillCustomHeader tableProps={props} title='Company' className='min-w-125px' />
//     ),
//     id: 'name',
//     Cell: ({...props}) => (
//       <CompanyLinkCell
//         id={props.data[props.row.index].companyId}
//         name={props.data[props.row.index].companyName || ''}
//       />
//     ),
//     // accessor: 'name',
//   },

//   {
//     Header: (props) => (
//       <WaybillCustomHeader tableProps={props} title='Site' className='min-w-125px' />
//     ),
//     accessor: 'siteName',
//   }
// )

export {waybillsColumns, waybillsColumnsWithCompanyAndSite}
