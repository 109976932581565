import {EBATCH_STATUS, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useSiteListView} from '../../core/SiteListViewProvider'
import {useSiteProvider} from '../../core/SiteProvider'
import {ESITE_QUERY_TYPE} from '../../core/_models'
import {useBatchProvider} from '../../../../../batch/core/BatchProvider'
import {useMutation, useQueryClient} from 'react-query'
import {processBatch} from '../../core/_requests'
import {useAuth} from '../../../../../auth'

export const SitesListToolbar = () => {
  const {siteQueryType} = useSiteProvider()
  const {currentUser} = useAuth()
  const {batchStatus, isBatchStatusFetching} = useBatchProvider()
  const queryClient = useQueryClient()
  const {setItemIdForUpdate, setProcessBatch} = useSiteListView()
  const openAddSiteModal = () => {
    setItemIdForUpdate(null)
  }

  // const onProcessBatch = useMutation(() => processBatch(currentUser), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.BATCH_STATUS}`])
  //   },
  // })

  const onProcessBatch = () => {
    setProcessBatch(true)
  }

  return (
    <>
      {siteQueryType === ESITE_QUERY_TYPE.SITES_BY_COMPANY && (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* begin::Add user */}
          <button type='button' className='btn btn-primary btn-sm' onClick={openAddSiteModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Site
          </button>
          {/* end::Add user */}
        </div>
      )}

      {siteQueryType === ESITE_QUERY_TYPE.SITES_IN_BATCH && (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* begin::Add user */}
          <button
            type='button'
            className='btn btn-primary btn-sm'
            disabled={
              isBatchStatusFetching || batchStatus === EBATCH_STATUS.STATUS_PROCESSING_BATCH
            }
            onClick={async () => {
              // queryClient.setQueryData(
              //   [`${QUERIES.BATCH_STATUS}`],
              //   EBATCH_STATUS.STATUS_PROCESSING_BATCH
              // )
              // setIsProcessingBatch(true)
              // await onProcessBatch.mutateAsync()
              onProcessBatch()
            }}
          >
            {isBatchStatusFetching && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
            {!isBatchStatusFetching &&
              batchStatus === EBATCH_STATUS.STATUS_NOT_PROCESSING_BATCH &&
              'Process & Print Batch'}
            {!isBatchStatusFetching && batchStatus === EBATCH_STATUS.STATUS_PROCESSING_BATCH && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Processing Batch...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {/* end::Add user */}
        </div>
      )}
    </>
  )
}
