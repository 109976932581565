import {CompanyListViewProvider, useCompanyListView} from './core/CompanyListViewProvider'
import {CompanyQueryRequestProvider} from './core/CompanyQueryRequestProvider'
import {CompanyQueryResponseProvider} from './core/CompanyQueryResponseProvider'
// import {UsersListHeader} from './components/header/UsersListHeader'
// import {UserEditModal} from './user-edit-modal/UserEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {CompaniesTable} from './table/CompaniesTable'
import {CompaniesListHeader} from './components/header/CompaniesListHeader'
import {CompanyEditModal} from './company-edit-modal/CompanyEditModal'

const CompaniesList = () => {
  const {itemIdForUpdate} = useCompanyListView()

  return (
    <>
      <KTCard>
        <CompaniesListHeader />
        <CompaniesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CompanyEditModal />}
    </>
  )
}

const CompaniesListWrapper = () => {
  return (
    <CompanyQueryRequestProvider>
      <CompanyQueryResponseProvider>
        <CompanyListViewProvider>
          <CompaniesList />
        </CompanyListViewProvider>
      </CompanyQueryResponseProvider>
    </CompanyQueryRequestProvider>
  )
}

export {CompaniesListWrapper}
