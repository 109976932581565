import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialSite, Site} from '../core/_models'
import {useAuth} from '../../../../auth'
import {useSiteQueryResponse} from '../core/SiteQueryResponseProvider'
import {useSiteListView} from '../core/SiteListViewProvider'
import {SitesListLoading} from '../components/loading/SitesListLoading'
import {SiteEditFormInput} from './SiteEditFormInput'
import {createSite, updateSite} from '../core/_requests'
import {useSiteProvider} from '../core/SiteProvider'

type Props = {
  companyId: string | undefined
  isSiteLoading: boolean
  site: Site
}

const editSiteSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Name is required'),
  code: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Code is required'),
  address1: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Address 1 is required'),
  address2: Yup.string().max(30, 'Maximum 30 symbols'),
  address3: Yup.string().max(30, 'Maximum 30 symbols'),
  address4: Yup.string().max(30, 'Maximum 30 symbols'),
  postCode: Yup.string().max(10, 'Maximum 30 symbols'),
  contactPerson: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Contact Person is required'),
  contactNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Contact Number is required'),
})

const SiteEditModalForm: FC<Props> = ({companyId, site, isSiteLoading}) => {
  const {company, setSite} = useSiteProvider()
  const {setItemIdForUpdate} = useSiteListView()
  const {refetch} = useSiteQueryResponse()
  const {currentUser} = useAuth()

  const [siteForEdit] = useState<Site>({
    ...site,
    name: site.name || initialSite.name,
    address1: site.id ? site.address1 : company.address1,
    address2: site.id ? site.address2 : company.address2,
    address3: site.id ? site.address3 : company.address3,
    address4: site.id ? site.address4 : company.address4,
    postCode: site.id ? site.postCode : company.postCode,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: siteForEdit,
    validationSchema: editSiteSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const site = await updateSite(currentUser, values)
          setSite(site)
        } else {
          await createSite(currentUser, companyId || '', values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-6'>
            <SiteEditFormInput
              label='Name'
              name='name'
              fieldProps={formik.getFieldProps('name')}
              touchedProp={formik.touched.name}
              errorsProp={formik.errors.name}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
            />

            <SiteEditFormInput
              label='Address'
              name='address1'
              fieldProps={formik.getFieldProps('address1')}
              touchedProp={formik.touched.address1}
              errorsProp={formik.errors.address1}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
            />

            <SiteEditFormInput
              label='Address 2'
              name='address2'
              fieldProps={formik.getFieldProps('address2')}
              touchedProp={formik.touched.address2}
              errorsProp={formik.errors.address2}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
              showLabel={false}
            />

            <SiteEditFormInput
              label='Address 3'
              name='address3'
              fieldProps={formik.getFieldProps('address3')}
              touchedProp={formik.touched.address3}
              errorsProp={formik.errors.address3}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
              showLabel={false}
            />

            <SiteEditFormInput
              label='Address 4'
              name='address4'
              fieldProps={formik.getFieldProps('address4')}
              touchedProp={formik.touched.address4}
              errorsProp={formik.errors.address4}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
              showLabel={false}
            />

            <SiteEditFormInput
              label='Post Code'
              name='postCode'
              fieldProps={formik.getFieldProps('postCode')}
              touchedProp={formik.touched.postCode}
              errorsProp={formik.errors.postCode}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
              isRequired={false}
            />
          </div>
          <div className='col-md-6'>
            <SiteEditFormInput
              label='Code'
              name='code'
              fieldProps={formik.getFieldProps('code')}
              touchedProp={formik.touched.code}
              errorsProp={formik.errors.code}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
            />

            <SiteEditFormInput
              label='Contact Person'
              name='contactPerson'
              fieldProps={formik.getFieldProps('contactPerson')}
              touchedProp={formik.touched.contactPerson}
              errorsProp={formik.errors.contactPerson}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
            />

            <SiteEditFormInput
              label='Contact Number'
              name='contactNumber'
              fieldProps={formik.getFieldProps('contactNumber')}
              touchedProp={formik.touched.contactNumber}
              errorsProp={formik.errors.contactNumber}
              isSubmitting={formik.isSubmitting}
              isLoading={isSiteLoading}
            />
          </div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isSiteLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isSiteLoading ||
              formik.isSubmitting ||
              !(formik.isValid && formik.dirty) ||
              !formik.touched
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isSiteLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isSiteLoading) && <SitesListLoading />}
    </>
  )
}

export {SiteEditModalForm}
