import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {CompaniesListWrapper} from './companies-list/ComapniesList'
import {CompanyWrapper} from './company-details/CompanyWrapper'

const companiesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Campany Management',
    path: '/apps/company-management/companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompaniesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='companies'
          element={
            <>
              <PageTitle breadcrumbs={companiesBreadcrumbs}>Companies list</PageTitle>
              <CompaniesListWrapper />
            </>
          }
        />
      </Route>
      <Route
        path='company/:companyId'
        element={
          <>
            <PageTitle breadcrumbs={companiesBreadcrumbs}>Company details</PageTitle>
            <CompanyWrapper />
          </>
        }
      ></Route>
      <Route index element={<Navigate to='/apps/company-management/companies' />} />
    </Routes>
  )
}

export default CompaniesPage
