import React from 'react'
import {useSiteListView} from '../../sites-list/core/SiteListViewProvider'
import {KTSVG} from '../../../../../../_metronic/helpers'

export const SiteBatchModalHeader = () => {
  const {setProcessBatch} = useSiteListView()
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Process Batch</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setProcessBatch(false)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}
