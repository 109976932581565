import {FC, useState, createContext, useContext} from 'react'
import {WithChildren} from '../../../../../../_metronic/helpers'
import {Site} from '../../../site-management/sites-list/core/_models'

type CompanyDetailsContextProps = {
  companyIdState?: string
  siteIdState?: string
  site?: Site
  setSite?: (site: Site) => void
  setSiteIdState?: (siteId: string) => void
  setCompanyIdState?: (companyId: string) => void
}

const initialCompanyDetails: CompanyDetailsContextProps = {}

const CompanyDetailsContext = createContext<CompanyDetailsContextProps>(initialCompanyDetails)

const CompanyDetailsProvider: FC<WithChildren> = ({children}) => {
  const [companyIdState, setCompanyIdState] = useState('')
  const [siteIdState, setSiteIdState] = useState('')
  const [site, setSite] = useState<Site>()

  return (
    <CompanyDetailsContext.Provider
      value={{companyIdState, siteIdState, setCompanyIdState, setSiteIdState, site, setSite}}
    >
      {children}
    </CompanyDetailsContext.Provider>
  )
}

const useCompanyDetails = () => useContext(CompanyDetailsContext)
export {CompanyDetailsProvider, useCompanyDetails}
