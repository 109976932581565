/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useSiteListView} from '../../core/SiteListViewProvider'
import {useMutation, useQueryClient} from 'react-query'
import {deleteSite, removeSitesFromBatch} from '../../core/_requests'
import {useAuth} from '../../../../../auth'
import {useSiteQueryResponse} from '../../core/SiteQueryResponseProvider'
import {useSiteProvider} from '../../core/SiteProvider'
// import {deleteUser} from '../../core/_requests'

type Props = {
  id: ID
  inBatch?: boolean
}

const SiteActionsCell: FC<Props> = ({id, inBatch}) => {
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const {query} = useSiteQueryResponse()
  const {setItemIdForUpdate} = useSiteListView()
  const [isDeleting, setDeleting] = useState(false)
  const [isRemoving, setRemoving] = useState(false)
  const {site, company} = useSiteProvider()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(
    () => {
      setDeleting(true)
      return deleteSite(currentUser, id)
    },
    {
      onSuccess: () => {
        setDeleting(false)
        queryClient.invalidateQueries([`${QUERIES.SITES_LIST}-${company.id}-${query}`])
      },
      onError: () => {
        setDeleting(false)
      },
    }
  )

  const removeSiteFromBatch = useMutation(
    () => {
      setRemoving(true)
      return removeSitesFromBatch(currentUser, [id])
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${QUERIES.BATCH_LIST}-${query}`])
        setRemoving(false)
      },
      onError: () => {
        setRemoving(false)
      },
    }
  )

  return (
    <>
      <div>
        <a
          className='btn btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Actions
          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Edit
            </a>
          </div>
          {/* end::Menu item */}

          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </a>
          </div>
          {/* end::Menu item */}

          {inBatch && (
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                onClick={async () => await removeSiteFromBatch.mutateAsync()}
              >
                Batch Remove
              </a>
            </div>
          )}
        </div>
        {/* end::Menu */}
      </div>

      {(isDeleting || isRemoving) && (
        <div>
          <span className='indicator-progress fs-7' style={{display: 'block'}}>
            Processing...{' '}
            {/* <span className='spinner-border spinner-border-sm align-middle ms-2'></span> */}
          </span>
        </div>
      )}
    </>
  )
}

export {SiteActionsCell}
