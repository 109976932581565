import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {useAuth} from '../../../../auth'
import {useWaybillListView} from '../core/WaybillListViewProvider'
import {WaybillEditFormInput} from './WaybillEditFormInput'
import {Waybill, initialWaybill} from '../core/_models'
import {useWaybillProvider} from '../core/WaybillProvider'
import {createWaybill} from '../core/_requests'
import {useWaybillQueryResponse} from '../core/WaybillQueryResponseProvider'
import {WaybillsListLoading} from '../components/loading/WaybillsLoading'

type Props = {
  isWaybillLoading: boolean
  waybill: Waybill
}

const editWaybillSchema = Yup.object().shape({
  siteName: Yup.string(),
  siteCode: Yup.string(),
  contactPerson: Yup.string(),
  contactNumber: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string(),
  address3: Yup.string(),
  address4: Yup.string(),
  postCode: Yup.string(),
  serviceType: Yup.string().required('Service Type is required'),
})

const WaybillEditModalForm: FC<Props> = ({waybill, isWaybillLoading}) => {
  const {setItemIdForUpdate} = useWaybillListView()
  const {company, site} = useWaybillProvider()
  const {refetch} = useWaybillQueryResponse()
  const {currentUser} = useAuth()

  const [waybillForEdit] = useState<Waybill>({
    ...waybill,
    waybillNumber: waybill.waybillNumber || initialWaybill.waybillNumber,
    companyName: company.name,
    companyId: company.id,
    siteName: site?.name,
    siteCode: site?.code,
    contactPerson: site?.contactPerson,
    contactNumber: site?.contactNumber,
    address1: site?.address1,
    address2: site?.address2,
    address3: site?.address3,
    address4: site?.address4,
    postCode: site?.postCode,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: waybillForEdit,
    validationSchema: editWaybillSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          // await updateSite(currentUser, values)
        } else {
          await createWaybill(currentUser, site?.id || '', values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-6'>
            <WaybillEditFormInput
              label='Site Code'
              name='siteCode'
              fieldProps={formik.getFieldProps('siteCode')}
              touchedProp={formik.touched.siteCode}
              errorsProp={formik.errors.siteCode}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={true}
            />

            <WaybillEditFormInput
              label='Address'
              name='address1'
              fieldProps={formik.getFieldProps('address1')}
              touchedProp={formik.touched.address1}
              errorsProp={formik.errors.address1}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={true}
            />

            <WaybillEditFormInput
              label='Address 2'
              name='address2'
              fieldProps={formik.getFieldProps('address2')}
              touchedProp={formik.touched.address2}
              errorsProp={formik.errors.address2}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              showLabel={false}
              disabled={true}
            />

            <WaybillEditFormInput
              label='Address 3'
              name='address3'
              fieldProps={formik.getFieldProps('address3')}
              touchedProp={formik.touched.address3}
              errorsProp={formik.errors.address3}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              showLabel={false}
              disabled={true}
            />

            <WaybillEditFormInput
              label='Address 4'
              name='address4'
              fieldProps={formik.getFieldProps('address4')}
              touchedProp={formik.touched.address4}
              errorsProp={formik.errors.address4}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              showLabel={false}
              disabled={true}
            />

            <WaybillEditFormInput
              label='Post Code'
              name='postCode'
              fieldProps={formik.getFieldProps('postCode')}
              touchedProp={formik.touched.postCode}
              errorsProp={formik.errors.postCode}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={true}
            />
          </div>
          <div className='col-md-6'>
            <WaybillEditFormInput
              label='Site Name'
              name='siteName'
              fieldProps={formik.getFieldProps('siteName')}
              touchedProp={formik.touched.siteName}
              errorsProp={formik.errors.siteName}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={true}
            />

            <WaybillEditFormInput
              label='Contact Person'
              name='contactPerson'
              fieldProps={formik.getFieldProps('contactPerson')}
              touchedProp={formik.touched.contactPerson}
              errorsProp={formik.errors.contactPerson}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={true}
            />

            <WaybillEditFormInput
              label='Contact Number'
              name='contactNumber'
              fieldProps={formik.getFieldProps('contactNumber')}
              touchedProp={formik.touched.contactNumber}
              errorsProp={formik.errors.contactNumber}
              isSubmitting={formik.isSubmitting}
              isLoading={isWaybillLoading}
              disabled={true}
            />

            <div className='fv-row mb-2'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Service Type</label>
              {/* end::Label */}

              {/* begin::Input */}

              <select
                className='form-select form-select-solid form-select-lg fs-7'
                {...formik.getFieldProps('serviceType')}
              >
                <option value=''>Select a Service Type..</option>
                <option value='EAD'>EAD</option>
              </select>

              {formik.touched.serviceType && formik.errors.serviceType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.serviceType}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
          </div>

          <WaybillEditFormInput
            label='Line 1'
            name='line1'
            fieldProps={formik.getFieldProps('line1')}
            touchedProp={formik.touched.line1}
            errorsProp={formik.errors.line1}
            isSubmitting={formik.isSubmitting}
            isLoading={isWaybillLoading}
            disabled={false}
          />
          <WaybillEditFormInput
            label='Line 2'
            name='line2'
            fieldProps={formik.getFieldProps('line2')}
            touchedProp={formik.touched.line2}
            errorsProp={formik.errors.line2}
            isSubmitting={formik.isSubmitting}
            isLoading={isWaybillLoading}
            disabled={false}
          />
          <WaybillEditFormInput
            label='Line 3'
            name='line3'
            fieldProps={formik.getFieldProps('line3')}
            touchedProp={formik.touched.line3}
            errorsProp={formik.errors.line3}
            isSubmitting={formik.isSubmitting}
            isLoading={isWaybillLoading}
            disabled={false}
          />
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isWaybillLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isWaybillLoading ||
              formik.isSubmitting ||
              !(formik.isValid && formik.dirty) ||
              !formik.touched
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isWaybillLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isWaybillLoading) && <WaybillsListLoading />}
    </>
  )
}

export {WaybillEditModalForm}
