import React, {FC} from 'react'

type Props = {
  content: string
}

const parseContent = (content: string) => {
  return content
    .replace(/\\t/g, '\t')
    .replace(/\\n/g, '\n')
    .replace(/&nbsp;/g, '\u00A0')
}

const CourierText: FC<Props> = ({content}) => {
  const parsedContent = parseContent(content)

  return (
    <pre
      className='p-3'
      style={{
        fontFamily: 'Courier, monospace',
        whiteSpace: 'pre-wrap',
        backgroundColor: 'white',
        color: 'black',
      }}
    >
      {parsedContent}
    </pre>
  )
}

export default CourierText
