import clsx from 'clsx'
import {KTSVG, QUERIES, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useQuery} from 'react-query'
import {useAuth} from '../../../../app/modules/auth'
import {getPrintAgentHeartbeat} from '../../core/_requests'
import {useEffect, useState} from 'react'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()
  const [heartbeatDiff, setHeartbeatDiff] = useState(0)
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  const {
    isFetching,
    refetch,
    data: iresponse,
  } = useQuery(
    `${QUERIES.HEARTBEAT}`,
    () => {
      setCurrentTime(new Date().getTime())
      return getPrintAgentHeartbeat(currentUser)
    },
    {
      refetchInterval: 30000,
    }
  )

  useEffect(() => {
    if (!isFetching && iresponse) {
      setHeartbeatDiff(currentTime - iresponse?.value)
    }
  }, [currentTime, iresponse, isFetching])

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)}>
          {/* <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} /> */}
          {isFetching ? (
            <div className='bullet bullet-dot bg-primary h-10px w-10px  animation-blink' />
          ) : (
            <>
              {heartbeatDiff < 60000 ? (
                <div className='bullet bullet-dot bg-success h-10px w-10px  animation-blink' />
              ) : (
                <div className='bullet bullet-dot bg-danger h-10px w-10px  animation-blink' />
              )}
            </>
          )}
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
