import {API} from 'aws-amplify'
import {UserModel} from '../../../../auth'
import {Batch, Site} from './_models'
import {ID} from '../../../../../../_metronic/helpers'

export const getSitesByCompanyId = async (
  currentUser: UserModel,
  companyId: string,
  query?: string
) => {
  return API.get(
    'app_base',
    `/organization/${currentUser.currentOrganization}/company/${companyId}/site?${query}`,
    {}
  )
}

export const get = async (id: string, currentUser: UserModel) => {
  const encodedUrl = encodeURIComponent(
    `/organization/${currentUser.currentOrganization}/site/${id}`
  )
  return API.get('app_base', encodedUrl, {})
}

export const getSitesInBatch = async (currentUser: UserModel, query?: string) => {
  return API.get(
    'app_base',
    `/organization/${currentUser.currentOrganization}/site/batch?${query}`,
    {}
  )
}

export const create = async (currentUser: UserModel, companyId: string, site: Site) => {
  const encodedUrl = encodeURIComponent(
    `/organization/${currentUser.currentOrganization}/company/${companyId}/site`
  )
  return API.post('app_base', encodedUrl, {body: site})
}

export const del = async (currentUser: UserModel, siteId: any) => {
  return API.del('app_base', `/organization/${currentUser.currentOrganization}/site/${siteId}`, {})
}

export const update = async (currentUser: UserModel, site: any) => {
  return API.put('app_base', `/organization/${currentUser.currentOrganization}/site/${site.id}`, {
    body: site,
  })
}

export const addSitesToBatch = async (currentUser: UserModel, sites: ID[]) => {
  return API.post('app_base', `/organization/${currentUser.currentOrganization}/site/batch`, {
    body: sites,
  })
}

export const processBatch = async (currentUser: UserModel, batch: Batch) => {
  return API.post(
    'app_base',
    `/organization/${currentUser.currentOrganization}/site/batch-process`,
    {
      body: batch,
    }
  )
}

export const removeSitesFromBatch = async (currentUser: UserModel, sites: ID[]) => {
  return API.post(
    'app_base',
    `/organization/${currentUser.currentOrganization}/site/batch-remove`,
    {
      body: sites,
    }
  )
}
