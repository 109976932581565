import {UserModel} from '../../../app/modules/auth'
import {getHeartbeat} from './LayoutHelpers.amplify'
const getPrintAgentHeartbeat = (currentUser: UserModel | undefined) => {
  if (currentUser) {
    return getHeartbeat(currentUser).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export {getPrintAgentHeartbeat}
