/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {
  createDynamoDbInfiniteResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useCompanyQueryRequest} from './CompanyQueryRequestProvider'
import {Company} from './_models'
import {useAuth} from '../../../../auth/core/Auth'
import {useInfiniteQuery} from 'react-query'
import {getCompanies} from './_requests'

const CompanyQueryResponseContext =
  createDynamoDbInfiniteResponseContext<Company>(initialQueryResponse)

const CompanyQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useCompanyQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const {currentUser} = useAuth()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: iresponse,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    `${QUERIES.COMPANIES_LIST}-${query}`,
    ({pageParam}) => {
      return getCompanies(query, currentUser, pageParam)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
    }
  )

  return (
    <CompanyQueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response: iresponse,
        query,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
      }}
    >
      {children}
    </CompanyQueryResponseContext.Provider>
  )
}

const useCompanyQueryResponse = () => useContext(CompanyQueryResponseContext)

const useCompanyQueryResponseData = () => {
  const {response} = useCompanyQueryResponse()
  if (!response) {
    return {
      pages: [],
    }
  }

  return response || {}
}

const useCompanyQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useCompanyQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useCompanyQueryResponseLoading = (): boolean => {
  const {isLoading} = useCompanyQueryResponse()
  return isLoading
}

const useCompanyQueryResponseFetchingNextPage = (): boolean => {
  const {isFetchingNextPage} = useCompanyQueryResponse()
  return isFetchingNextPage
}

const useCompanyQueryResponseFetchNextPage = (): (() => void) => {
  const {fetchNextPage} = useCompanyQueryResponse()
  return fetchNextPage
}

export {
  CompanyQueryResponseProvider,
  useCompanyQueryResponse,
  useCompanyQueryResponseData,
  useCompanyQueryResponsePagination,
  useCompanyQueryResponseLoading,
  useCompanyQueryResponseFetchNextPage,
  useCompanyQueryResponseFetchingNextPage,
}
