import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialQueryState, isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialCompany, Company} from '../core/_models'
import {useCompanyListView} from '../core/CompanyListViewProvider'
import {createCompany, updateCompany} from '../core/_requests'
import {useCompanyQueryResponse} from '../core/CompanyQueryResponseProvider'
import {CompaniesListLoading} from '../components/loading/CompaniesListLoading'
import {CompanyEditFormInput} from './CompanyEditFormInput'
import {useAuth} from '../../../../auth'
import {useCompanyQueryRequest} from '../core/CompanyQueryRequestProvider'
type Props = {
  isCompanyLoading: boolean
  company: Company
}

const editCompanySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Name is required'),
  accountNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Account Number is required'),
  address1: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Address 1 is required'),
  address2: Yup.string().max(30, 'Maximum 30 symbols'),
  address3: Yup.string().max(30, 'Maximum 30 symbols'),
  address4: Yup.string().max(30, 'Maximum 30 symbols'),
  postCode: Yup.string().max(10, 'Maximum 30 symbols'),
})

export const CompanyEditModalForm: FC<Props> = ({company, isCompanyLoading}) => {
  const {updateState, setSearchTerm} = useCompanyQueryRequest()
  const {setItemIdForUpdate} = useCompanyListView()
  const {refetch} = useCompanyQueryResponse()
  const {currentUser} = useAuth()

  const [companyForEdit] = useState<Company>({
    ...company,
    name: company.name || initialCompany.name,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: companyForEdit,
    validationSchema: editCompanySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const company = await updateCompany(currentUser, values)
          setSearchTerm(company?.name || '')
          updateState({search: company?.name, ...initialQueryState})
        } else {
          const company = await createCompany(currentUser, values)
          setSearchTerm(company?.name || '')
          updateState({search: company?.name, ...initialQueryState})
        }

        //
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-6'>
            <CompanyEditFormInput
              label='Name'
              name='name'
              fieldProps={formik.getFieldProps('name')}
              touchedProp={formik.touched.name}
              errorsProp={formik.errors.name}
              isSubmitting={formik.isSubmitting}
              isLoading={isCompanyLoading}
            />
            <CompanyEditFormInput
              label='Account Number'
              name='accountNumber'
              fieldProps={formik.getFieldProps('accountNumber')}
              touchedProp={formik.touched.accountNumber}
              errorsProp={formik.errors.accountNumber}
              isSubmitting={formik.isSubmitting}
              isLoading={isCompanyLoading}
            />
          </div>
          <div className='col-md-6'>
            <CompanyEditFormInput
              label='Address 1'
              name='address1'
              fieldProps={formik.getFieldProps('address1')}
              touchedProp={formik.touched.address1}
              errorsProp={formik.errors.address1}
              isSubmitting={formik.isSubmitting}
              isLoading={isCompanyLoading}
            />

            <CompanyEditFormInput
              label='Address 2'
              name='address2'
              fieldProps={formik.getFieldProps('address2')}
              touchedProp={formik.touched.address2}
              errorsProp={formik.errors.address2}
              isSubmitting={formik.isSubmitting}
              isLoading={isCompanyLoading}
              isRequired={false}
            />

            <CompanyEditFormInput
              label='Address 3'
              name='address3'
              fieldProps={formik.getFieldProps('address3')}
              touchedProp={formik.touched.address3}
              errorsProp={formik.errors.address3}
              isSubmitting={formik.isSubmitting}
              isLoading={isCompanyLoading}
              isRequired={false}
            />

            <CompanyEditFormInput
              label='Address 4'
              name='address4'
              fieldProps={formik.getFieldProps('address4')}
              touchedProp={formik.touched.address4}
              errorsProp={formik.errors.address4}
              isSubmitting={formik.isSubmitting}
              isLoading={isCompanyLoading}
              isRequired={false}
            />

            <CompanyEditFormInput
              label='Post Code'
              name='postCode'
              fieldProps={formik.getFieldProps('postCode')}
              touchedProp={formik.touched.postCode}
              errorsProp={formik.errors.postCode}
              isSubmitting={formik.isSubmitting}
              isLoading={isCompanyLoading}
              isRequired={false}
            />
          </div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isCompanyLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isCompanyLoading ||
              formik.isSubmitting ||
              !(formik.isValid && formik.dirty) ||
              !formik.touched
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCompanyLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCompanyLoading) && <CompaniesListLoading />}
    </>
  )
}
