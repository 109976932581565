import React, {FC, useEffect, useMemo} from 'react'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {ColumnInstance, Row, useTable} from 'react-table'
import {waybillsColumns, waybillsColumnsWithCompanyAndSite} from './columns/_columns'
import {Waybill} from '../core/_models'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useWaybillQueryResponseData,
  useWaybillQueryResponseFetchNextPage,
  useWaybillQueryResponseHasMoreData,
  useWaybillQueryResponseLoading,
} from '../core/WaybillQueryResponseProvider'
// import {
//   useSiteQueryResponseData,
//   useSiteQueryResponseFetchNextPage,
//   useSiteQueryResponseLoading,
// } from '../core/SiteQueryResponseProvider'

type Props = {
  showCompanyAndSiteName?: boolean
}
export const WaybillsTable: FC<Props> = ({showCompanyAndSiteName}) => {
  const {pages} = useWaybillQueryResponseData()
  const isLoading = useWaybillQueryResponseLoading()
  const hasMorePages = useWaybillQueryResponseHasMoreData()
  const fetchNextPage = useWaybillQueryResponseFetchNextPage()
  const columns = useMemo(() => {
    return showCompanyAndSiteName ? waybillsColumnsWithCompanyAndSite : waybillsColumns
  }, [showCompanyAndSiteName])

  const data: Waybill[] = useMemo(() => {
    return pages ? pages.flatMap((page) => page.data?.Items || []) : []
  }, [pages])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Waybill>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Waybill>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <button
        onClick={() => fetchNextPage()}
        className='btn btn-primary btn-sm mt-5'
        disabled={isLoading || !hasMorePages}
      >
        {!isLoading && 'Load More'}
        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>

      {/* {(isLoading || isFetchingNextPage) && <CompaniesListLoading />} */}
    </KTCardBody>
  )
}
