import React, {FC, useEffect} from 'react'
import {KTCard} from '../../../../../_metronic/helpers'

import {WaybillsListHeader} from './components/header/WaybillsListHeader'
import {WaybillsTable} from './table/WaybillsTable'
import {Site} from '../../site-management/sites-list/core/_models'
import {WaybillListViewProvider, useWaybillListView} from './core/WaybillListViewProvider'
import {WaybillEditModal} from './waybill-edit-modal/WaybillEditModal'
import {WaybillQueryRequestProvider} from './core/WaybillQueryRequestProvider'
import {WaybillQueryResponseProvider} from './core/WaybillQueryResponseProvider'
import {WaybillPrintModal} from './waybill-print-modal/WaybillPrintModal'
import {Tab, Tabs} from 'react-bootstrap'
import {Company} from '../../company-management/companies-list/core/_models'

type Props = {
  site?: Site
  setSite?: (site: Site) => void
}

export const WaybillsList: FC<Props> = ({site, setSite}) => {
  const {itemIdForUpdate, waybillsForPrint} = useWaybillListView()

  return (
    <>
      {/* <KTCard> */}
      <WaybillsListHeader />
      <WaybillsTable showCompanyAndSiteName={site ? false : true} />
      {/* </KTCard> */}
      {itemIdForUpdate !== undefined && <WaybillEditModal />}
      {waybillsForPrint !== undefined && <WaybillPrintModal />}
    </>
  )
}

export const WaybillsListWrapper: FC<Props> = ({site, setSite}) => {
  const kfcCompany: Company = {
    id: '924fcf34-a3ad-483f-9925-61c0319eda8d',
  }

  const nedbankCompany: Company = {
    id: '19cb647b-c4e1-4b2a-9b0b-1e8bad4a631e',
  }

  if (site) {
    return (
      <KTCard>
        <WaybillQueryRequestProvider>
          <WaybillQueryResponseProvider siteId={site.id || ''}>
            <WaybillListViewProvider>
              <WaybillsList site={site} setSite={setSite} />
            </WaybillListViewProvider>
          </WaybillQueryResponseProvider>
        </WaybillQueryRequestProvider>
      </KTCard>
    )
  } else {
    return (
      <KTCard>
        <Tabs defaultActiveKey='home' id='uncontrolled-tab-example'>
          <Tab eventKey='home' title='Home'>
            <WaybillQueryRequestProvider>
              <WaybillQueryResponseProvider>
                <WaybillListViewProvider>
                  <WaybillsList />
                </WaybillListViewProvider>
              </WaybillQueryResponseProvider>
            </WaybillQueryRequestProvider>
          </Tab>
          <Tab eventKey='kfc' title='KFC HEAD OFFICE (YUM)'>
            <WaybillQueryRequestProvider>
              <WaybillQueryResponseProvider companyId={kfcCompany.id || ''}>
                <WaybillListViewProvider>
                  <WaybillsList />
                </WaybillListViewProvider>
              </WaybillQueryResponseProvider>
            </WaybillQueryRequestProvider>
          </Tab>
          <Tab eventKey='nedbank' title='NEDBANK'>
            <WaybillQueryRequestProvider>
              <WaybillQueryResponseProvider companyId={nedbankCompany.id || ''}>
                <WaybillListViewProvider>
                  <WaybillsList />
                </WaybillListViewProvider>
              </WaybillQueryResponseProvider>
            </WaybillQueryRequestProvider>
          </Tab>
        </Tabs>
      </KTCard>
    )
  }
}
