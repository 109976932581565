import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {CompanyLoading} from './components/loading/CompanyLoading'

export const CompanyHeader = ({company, isLoading}: any) => {
  const {companyId} = useParams()

  return (
    <>
      <KTCard className='mb-5'>
        <div>
          <div className='card-body pt-2 pb-1'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center'>
                        <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                          {company?.name}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap'>
                    <div className='flex-equal me-5'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Account Number</td>
                            <td className='text-gray-800'>{company?.accountNumber}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>ID</td>
                            <td className='text-gray-800'>{company?.id}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='flex-equal'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 1</td>
                            <td className='text-gray-800'>{company?.address1}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 2</td>
                            <td className='text-gray-800'>{company?.address2}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 3</td>
                            <td className='text-gray-800'>{company?.address3}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 4</td>
                            <td className='text-gray-800'>{company?.address4}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Post Code</td>
                            <td className='text-gray-800'>{company?.postCode}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}
