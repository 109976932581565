import {useQuery} from 'react-query'
import {CompanyHeader} from './CompanyHeader'
import {QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {getCompanyById} from '../companies-list/core/_requests'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {CompanyDetails} from './CompanyDetails'
import {CompanyLoading} from './components/loading/CompanyLoading'
import {CompanyDetailsProvider} from './core/CompanyDetailsProvider'

export const CompanyWrapper = () => {
  const {currentUser} = useAuth()
  const {companyId} = useParams()
  const enabledQuery: boolean = isNotEmpty(companyId)
  const {
    isLoading,
    data: company,
    error,
  } = useQuery(
    `${QUERIES.COMPANY_DETAILS}-company-${companyId}`,
    () => {
      return getCompanyById(companyId, currentUser)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {isLoading ? (
        <>
          <CompanyLoading />
        </>
      ) : (
        <>
          <CompanyDetailsProvider>
            <CompanyHeader company={company} isLoading={isLoading} />
            <CompanyDetails company={company} isLoading={isLoading} />
          </CompanyDetailsProvider>
        </>
      )}
    </>
  )
}
