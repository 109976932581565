/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useCompanyListView} from '../../core/CompanyListViewProvider'
import {useMutation, useQueryClient} from 'react-query'
import {deleteCompany} from '../../core/_requests'
import {useAuth} from '../../../../../auth'
import {useCompanyQueryResponse} from '../../core/CompanyQueryResponseProvider'
// import {deleteUser} from '../../core/_requests'

type Props = {
  id: ID
}

const CompanyActionsCell: FC<Props> = ({id}) => {
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const {query} = useCompanyQueryResponse()
  const {setItemIdForUpdate} = useCompanyListView()
  const [isDeleting, setDeleting] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(
    () => {
      setDeleting(true)
      return deleteCompany(currentUser, id)
    },
    {
      onSuccess: () => {
        setDeleting(false)
        queryClient.invalidateQueries([`${QUERIES.COMPANIES_LIST}-${query}`])
      },
      onError: () => {
        setDeleting(false)
      },
    }
  )

  return (
    <>
      <div>
        <a
          className='btn btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Actions
          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Edit
            </a>
          </div>
          {/* end::Menu item */}

          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </a>
          </div>
          {/* end::Menu item */}
        </div>
        {/* end::Menu */}
      </div>

      {isDeleting && (
        <div>
          <span className='indicator-progress fs-7' style={{display: 'block'}}>
            Deleting... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      )}
    </>
  )
}

export {CompanyActionsCell}
