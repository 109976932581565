import {FC} from 'react'
import {Site} from '../../core/_models'

type Props = {
  name: string
  site: Site
  setSite: (site: Site) => void
  scrollTo: () => void
}

export const SiteLinkCell: FC<Props> = ({name, site, setSite, scrollTo}) => {
  const selectSite = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    if (setSite) {
      setSite(site)
    }
    scrollTo()
  }

  return (
    <>
      <a href='#' onClick={selectSite}>
        {name}
      </a>
    </>
  )
}
