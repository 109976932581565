import {DynamoDbResponse, ID} from '../../../../../../_metronic/helpers'

export const enum ESITE_QUERY_TYPE {
  SITES_BY_COMPANY = 'SITES_BY_COMPANY',
  SITES_IN_BATCH = 'SITES_IN_BATCH',
}

export type Batch = {
  line1?: string
  line2?: string
  line3?: string
  serviceType?: string
  waybillPrintCount?: number
  labelPrintCount?: number
}

export type Site = {
  id?: ID
  companyId?: string
  name?: string
  code?: string
  contactPerson?: string
  contactNumber?: string
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  postCode?: string
  legacySiteId?: number
  legacyCompanyId?: number
  inBatch?: boolean
}

export const initialSite: Site = {
  name: '',
  address1: '',
  address2: '',
  address3: '',
  address4: '',
  postCode: '',
  contactPerson: 'indran',
  contactNumber: 'naidoo',
}

export type SiteQueryResponse = DynamoDbResponse<Array<Site>>
