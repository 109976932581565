import React, {FC, useEffect} from 'react'
import {KTCard} from '../../../../../_metronic/helpers'
import {SitesListHeader} from './components/header/SitesListHeader'
import {SitesTable} from './table/SitesTable'
import {SiteQueryRequestProvider} from './core/SiteQueryRequestProvider'
import {SiteQueryResponseProvider} from './core/SiteQueryResponseProvider'
import {SiteListViewProvider, useSiteListView} from './core/SiteListViewProvider'
import {SiteEditModal} from './site-edit-modal/SiteEditModal'
import {useSiteProvider} from './core/SiteProvider'
import {SiteBatchModal} from '../site-batch/site-batch-modal/SiteBatchModal'

export const SitesList = () => {
  const {itemIdForUpdate, processBatch} = useSiteListView()

  return (
    <>
      <KTCard>
        <SitesListHeader />
        <SitesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <SiteEditModal />}
      {processBatch && <SiteBatchModal />}
    </>
  )
}

export const SitesListWrapper = () => {
  const {company, isCompanyLoading} = useSiteProvider()

  if (isCompanyLoading) {
    return <div>Loading...</div>
  }

  if (company.id) {
    return (
      <SiteQueryRequestProvider>
        <SiteQueryResponseProvider>
          <SiteListViewProvider>
            <SitesList />
          </SiteListViewProvider>
        </SiteQueryResponseProvider>
      </SiteQueryRequestProvider>
    )
  } else {
    return (
      <SiteQueryRequestProvider>
        <SiteQueryResponseProvider>
          <SiteListViewProvider>
            <SitesList />
          </SiteListViewProvider>
        </SiteQueryResponseProvider>
      </SiteQueryRequestProvider>
    )
  }
}
