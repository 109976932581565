import {DynamoDbInfiniteResponse, DynamoDbResponse, ID} from '../../../../../../_metronic/helpers'

export type Company = {
  id?: ID
  name?: string
  accountNumber?: string
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  postCode?: string
}

export const initialCompany: Company = {
  name: '',
  accountNumber: '',
  address1: '',
  address2: '',
  address3: '',
  address4: '',
  postCode: '',
}

export type CompanyQueryResponse = DynamoDbResponse<Array<Company>>

export type CompanyInfiniteQueryResponse = DynamoDbInfiniteResponse<Array<Company>>
