import {useQuery} from 'react-query'
import {SiteEditModalForm} from './SiteEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useSiteListView} from '../core/SiteListViewProvider'
import {getSiteById} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {SitesListLoading} from '../components/loading/SitesListLoading'
import {FC, useEffect} from 'react'
import {useSiteProvider} from '../core/SiteProvider'

const SiteEditModalFormWrapper = () => {
  const {currentUser} = useAuth()
  const {company, setSite, setSiteLoading} = useSiteProvider()
  const {itemIdForUpdate, setItemIdForUpdate} = useSiteListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: siteData,
    error,
  } = useQuery(
    `${QUERIES.SITES_LIST}-site-${itemIdForUpdate}`,
    () => {
      return getSiteById(itemIdForUpdate, currentUser)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  useEffect(() => {
    console.log({siteData})
    setSite(siteData)
    setSiteLoading(isLoading)
  }, [setSite, setSiteLoading, isLoading, siteData])

  if (!itemIdForUpdate) {
    return (
      <SiteEditModalForm
        companyId={company.id || ''}
        isSiteLoading={isLoading}
        site={{id: undefined}}
      />
    )
  }

  if (!isLoading && !error && siteData) {
    return (
      <SiteEditModalForm companyId={company.id || ''} isSiteLoading={isLoading} site={siteData} />
    )
  }

  if (isLoading) {
    return <SitesListLoading />
  }

  return null
}

export {SiteEditModalFormWrapper}
