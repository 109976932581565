import {FC, useState, createContext, useContext, useMemo, Dispatch, SetStateAction} from 'react'
import {
  ID,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
  calculatedGroupingIsDisabled,
} from '../../../../../../_metronic/helpers'
import {Waybill} from './_models'
import {useWaybillQueryResponse, useWaybillQueryResponseData} from './WaybillQueryResponseProvider'
// import {useWaybillQueryResponse} from './WaybillQueryResponseProvider'

export type WaybillListViewContextProps = ListViewContextProps & {
  waybillsForPrint?: Waybill[]
  setWaybillsForPrint: Dispatch<SetStateAction<Waybill[] | undefined>>
  selectedData: Waybill[]
}

export const waybillInitialListView: WaybillListViewContextProps = {
  ...initialListView,
  setWaybillsForPrint: () => {},
  selectedData: [],
}

const WaybillListViewContext = createContext<WaybillListViewContextProps>(waybillInitialListView)

const WaybillListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [waybillsForPrint, setWaybillsForPrint] = useState<Waybill[]>()
  const {isLoading} = useWaybillQueryResponse()

  const {pages} = useWaybillQueryResponseData()
  const data: Waybill[] = useMemo(() => {
    return pages ? pages.flatMap((page) => page.data?.Items || []) : []
  }, [pages])

  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const getSelectedData = (selected: Array<ID>, data: Waybill[]) => {
    return data.filter((item) => {
      return selected.includes(item.id)
    })
  }

  return (
    <WaybillListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
        waybillsForPrint,
        setWaybillsForPrint,
        selectedData: getSelectedData(selected, data),
      }}
    >
      {children}
    </WaybillListViewContext.Provider>
  )
}

const useWaybillListView = () => useContext(WaybillListViewContext)

export {WaybillListViewProvider, useWaybillListView}
