import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../../_metronic/helpers'

const CompanyQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const CompanyQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState

    setState(updatedState)
  }

  return (
    <CompanyQueryRequestContext.Provider value={{state, updateState, searchTerm, setSearchTerm}}>
      {children}
    </CompanyQueryRequestContext.Provider>
  )
}

const useCompanyQueryRequest = () => useContext(CompanyQueryRequestContext)
export {CompanyQueryRequestProvider, useCompanyQueryRequest}
