import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useCompanyQueryResponseData,
  useCompanyQueryResponseFetchNextPage,
  useCompanyQueryResponseFetchingNextPage,
  useCompanyQueryResponseLoading,
} from '../core/CompanyQueryResponseProvider'
import {companiesColumns} from './columns/_columns'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {Company} from '../core/_models'
import {CompaniesListPagination} from '../components/pagination/CompaniesListPagination'

const CompaniesTable = () => {
  const {pages} = useCompanyQueryResponseData()
  const isLoading = useCompanyQueryResponseLoading()
  const fetchNextPage = useCompanyQueryResponseFetchNextPage()
  const isFetchingNextPage = useCompanyQueryResponseFetchingNextPage()

  const data: Company[] = useMemo(() => {
    return pages ? pages.flatMap((page) => page.data?.Items || []) : []
  }, [pages])

  const columns = useMemo(() => companiesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Company>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Company>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <button
        onClick={() => fetchNextPage()}
        className='btn btn-primary btn-sm mt-5'
        disabled={isLoading}
      >
        {!isLoading && 'Load More'}
        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>

      <CompaniesListPagination />
      {/* {(isLoading || isFetchingNextPage) && <CompaniesListLoading />} */}
    </KTCardBody>
  )
}

export {CompaniesTable}
