import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../../_metronic/helpers'

const SiteQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const SiteQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)
  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
    <SiteQueryRequestContext.Provider value={{state, updateState, searchTerm, setSearchTerm}}>
      {children}
    </SiteQueryRequestContext.Provider>
  )
}

const useSiteQueryRequest = () => useContext(SiteQueryRequestContext)
export {SiteQueryRequestProvider, useSiteQueryRequest}
