import {useQuery} from 'react-query'
import {CompanyEditModalForm} from './CompanyEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useCompanyListView} from '../core/CompanyListViewProvider'
import {getCompanyById} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {CompaniesListLoading} from '../components/loading/CompaniesListLoading'

const CompanyEditModalFormWrapper = () => {
  const {currentUser} = useAuth()

  const {itemIdForUpdate, setItemIdForUpdate} = useCompanyListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.COMPANIES_LIST}-company-${itemIdForUpdate}`,
    () => {
      return getCompanyById(itemIdForUpdate, currentUser)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <CompanyEditModalForm isCompanyLoading={isLoading} company={{id: undefined}} />
  }

  if (!isLoading && !error && user) {
    console.log({user})
    return <CompanyEditModalForm isCompanyLoading={isLoading} company={user} />
  }

  if (isLoading) {
    return <CompaniesListLoading />
  }

  return null
}

export {CompanyEditModalFormWrapper}
