import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
  SiteListViewContextProps,
  initialSiteListView,
} from '../../../../../../_metronic/helpers'
import {useSiteQueryResponse, useSiteQueryResponseData} from './SiteQueryResponseProvider'
import {Site} from './_models'

const SiteListViewContext = createContext<SiteListViewContextProps>(initialSiteListView)

const SiteListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [processBatch, setProcessBatch] = useState<boolean>(false)
  const {isLoading} = useSiteQueryResponse()

  const {pages} = useSiteQueryResponseData()
  const data: Site[] = useMemo(() => {
    return pages ? pages.flatMap((page) => page.data?.Items || []) : []
  }, [pages])

  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  return (
    <SiteListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        processBatch,
        setProcessBatch,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </SiteListViewContext.Provider>
  )
}

const useSiteListView = () => useContext(SiteListViewContext)

export {SiteListViewProvider, useSiteListView}
