import React, {FC} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'

type Props = {
  inBatch?: boolean
}

export const SiteBatchCell: FC<Props> = ({inBatch}) => {
  return (
    <>
      {inBatch ? (
        <div>
          <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-2' />
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}
