import {useQuery} from 'react-query'
// import {SiteEditModalForm} from './SiteEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
// import {useSiteListView} from '../core/SiteListViewProvider'
// import {getSiteById} from '../core/_requests'
import {useAuth} from '../../../../auth'
// import {SitesListLoading} from '../components/loading/SitesListLoading'
import {FC} from 'react'
import {useWaybillListView} from '../core/WaybillListViewProvider'
import {WaybillEditModalForm} from './WaybillEditModalForm'

type Props = {}

const WaybillEditModalFormWrapper: FC<Props> = () => {
  const {currentUser} = useAuth()

  const {itemIdForUpdate, setItemIdForUpdate} = useWaybillListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  // const {
  //   isLoading,
  //   data: user,
  //   error,
  // } = useQuery(
  //   `${QUERIES.SITES_LIST}-site-${itemIdForUpdate}`,
  //   () => {
  //     return getSiteById(itemIdForUpdate, currentUser)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  if (!itemIdForUpdate) {
    return <WaybillEditModalForm isWaybillLoading={false} waybill={{id: undefined}} />
  }

  // if (!isLoading && !error && user) {
  //   console.log({user})
  //   return <SiteEditModalForm companyId={companyId} isSiteLoading={isLoading} site={user} />
  // }

  // if (isLoading) {
  //   return <SitesListLoading />
  // }

  return null
}

export {WaybillEditModalFormWrapper}
