import {API} from 'aws-amplify'
import {UserModel} from '../../../../auth'
import {Waybill} from './_models'
import {ID} from '../../../../../../_metronic/helpers'

export const getWaybillsBySiteIdRequest = async (
  currentUser: UserModel,
  siteId: string,
  query?: string
) => {
  return API.get(
    'app_base',
    `/organization/${currentUser.currentOrganization}/site/${siteId}/waybill?${query}`,
    {}
  )
}

export const getWaybillsByCompanyIdRequest = async (
  currentUser: UserModel,
  companyId: string,
  query?: string
) => {
  return API.get(
    'app_base',
    `/organization/${currentUser.currentOrganization}/company/${companyId}/waybill?${query}`,
    {}
  )
}

export const getWaybillsRequest = async (currentUser: UserModel, query?: string) => {
  return API.get(
    'app_base',
    `/organization/${currentUser.currentOrganization}/waybill?${query}`,
    {}
  )
}

export const create = async (currentUser: UserModel, siteId: string, waybill: Waybill) => {
  const encodedUrl = encodeURIComponent(
    `/organization/${currentUser.currentOrganization}/site/${siteId}/waybill`
  )
  return API.post('app_base', encodedUrl, {body: waybill})
}

export const print = async (
  currentUser: UserModel,
  waybills: Waybill[],
  waybillPrintCount: number,
  labelPrintCount: number
) => {
  const waybillIds: ID[] = waybills.map((waybill) => waybill.id)

  const encodedUrl = encodeURIComponent(
    `/organization/${currentUser.currentOrganization}/waybill/print`
  )
  return API.post('app_base', encodedUrl, {body: {waybillPrintCount, labelPrintCount, waybillIds}})
}
