import React from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useCompanyListView} from '../../core/CompanyListViewProvider'

export const CompaniesListToolbar = () => {
  const {setItemIdForUpdate} = useCompanyListView()
  const openAddCompanyModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary btn-sm' onClick={openAddCompanyModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Company
      </button>
      {/* end::Add user */}
    </div>
  )
}
