import {AuthModel, UserModel} from './_models'
import {Auth} from 'aws-amplify'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

const login = async (email: string, password: string) => {
  const cognitoUser = await Auth.signIn(email, password)
  return createAuthAndUserModels(cognitoUser)
}

const getCurrentUser = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const {authModel, userModel} = createAuthAndUserModels(cognitoUser)
  return {authModel, userModel}
}

const createAuthAndUserModels = (cognitoUser: any) => {
  const authModel: AuthModel = {
    api_token: cognitoUser.signInUserSession.accessToken.jwtToken,
    refreshToken: cognitoUser.signInUserSession.idToken.jwtToken,
  }

  const userModel: UserModel = {
    id: cognitoUser.attributes.sub,
    email: cognitoUser.attributes.email,
    username: cognitoUser.attributes.email,
    first_name: cognitoUser.attributes['custom:firstName'],
    last_name: cognitoUser.attributes['custom:lastName'],
    currentOrganization: cognitoUser.attributes['custom:currentOrganization'],
    password: '',
  }

  return {authModel, userModel}
}

const logout = async () => {
  await Auth.signOut()
}

export {getAuth, setAuth, removeAuth, login, logout, getCurrentUser, AUTH_LOCAL_STORAGE_KEY}
