import {SiteProvider} from '../sites-list/core/SiteProvider'
import {KTCard} from '../../../../../_metronic/helpers'
import {SitesListWrapper} from '../sites-list/SitesList'
import {useState} from 'react'
import {ESITE_QUERY_TYPE, Site} from '../sites-list/core/_models'
import {Company} from '../../company-management/companies-list/core/_models'

export const SitesBatch = () => {
  const [company, setCompany] = useState<Company>()
  const [site, setSite] = useState<Site>()

  const [isCompanyLoading, setCompanyLoading] = useState<boolean>(false)
  const [isSiteLoading, setSiteLoading] = useState<boolean>(false)

  return (
    <>
      <SiteProvider
        company={{}}
        site={site}
        setCompany={setCompany}
        setSite={setSite}
        setCompanyLoading={setCompanyLoading}
        setSiteLoading={setSiteLoading}
        isCompanyLoading={isCompanyLoading}
        isSiteLoading={isSiteLoading}
        scrollTo={() => {}}
        siteQueryType={ESITE_QUERY_TYPE.SITES_IN_BATCH}
      >
        <div className=' g-5 g-xxl-8 mb-5'>
          <KTCard>
            <div className=''>
              <div className=' pt-2 pb-1'>
                <div className=''>
                  <div className='flex-grow-1'>
                    <SitesListWrapper />
                  </div>
                </div>
              </div>
            </div>
          </KTCard>
        </div>
      </SiteProvider>
    </>
  )
}
