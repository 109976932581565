import React from 'react'
import {KTCard} from '../../../../../_metronic/helpers'
import {useSiteProvider} from '../sites-list/core/SiteProvider'

export const SiteDetails = () => {
  const {site} = useSiteProvider()

  return (
    <>
      <KTCard className='mb-5'>
        <div>
          <div className='card-body pt-2 pb-1'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center'>
                        <a
                          href='/'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          {site?.name}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap'>
                    <div className='flex-equal me-5'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Code</td>
                            <td className='text-gray-800'>{site?.code}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Contact Person</td>
                            <td className='text-gray-800'>{site?.contactPerson}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Contact Number</td>
                            <td className='text-gray-800'>{site?.contactNumber}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>ID</td>
                            <td className='text-gray-800'>{site?.id}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>In Batch</td>
                            <td className='text-gray-800'>{JSON.stringify(site?.inBatch)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='flex-equal'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 1</td>
                            <td className='text-gray-800'>{site?.address1}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 2</td>
                            <td className='text-gray-800'>{site?.address2}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 3</td>
                            <td className='text-gray-800'>{site?.address3}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Address 4</td>
                            <td className='text-gray-800'>{site?.address4}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Post Code</td>
                            <td className='text-gray-800'>{site?.postCode}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}
