import {useWaybillListView} from '../../core/WaybillListViewProvider'

export const WaybillsListGrouping = () => {
  const {selected, selectedData, setWaybillsForPrint} = useWaybillListView()

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>
      <button
        type='button'
        className='btn btn-sm btn-primary me-3'
        onClick={() => {
          setWaybillsForPrint(selectedData)
        }}
      >
        Print Selected
      </button>
    </div>
  )
}
