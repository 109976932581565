/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {
  createDynamoDbInfiniteResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {ESITE_QUERY_TYPE, Site} from './_models'
import {useAuth} from '../../../../auth/core/Auth'
import {useInfiniteQuery} from 'react-query'
import {useSiteQueryRequest} from './SiteQueryRequestProvider'
import {getSitesByCompanyId, getSitesInBatch} from './_requests'
import {useSiteProvider} from './SiteProvider'
import {Company} from '../../../company-management/companies-list/core/_models'

const SiteQueryResponseContext = createDynamoDbInfiniteResponseContext<Site>(initialQueryResponse)

const SiteQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useSiteQueryRequest()
  const {company, siteQueryType} = useSiteProvider()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {currentUser} = useAuth()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const generateQueryKey = (
    siteQueryType: ESITE_QUERY_TYPE,
    key: string,
    company: Company,
    query: string
  ) => {
    if (siteQueryType === ESITE_QUERY_TYPE.SITES_BY_COMPANY) {
      return `${QUERIES.SITES_LIST}-${company.id}-${query}`
    } else if (siteQueryType === ESITE_QUERY_TYPE.SITES_IN_BATCH) {
      return `${QUERIES.BATCH_LIST}-${query}`
    } else {
      return `${QUERIES.SITES_LIST}-${query}`
    }
  }

  const {
    isFetching,
    refetch,
    data: iresponse,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    generateQueryKey(siteQueryType, QUERIES.SITES_LIST, company, query),
    ({pageParam}) => {
      if (company && company.id) {
        return getSitesByCompanyId(company.id || '', query, currentUser, pageParam)
      } else {
        return getSitesInBatch(query, currentUser, pageParam)
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
      refetchInterval: 10000,
    }
  )

  return (
    <SiteQueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response: iresponse,
        query,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
      }}
    >
      {children}
    </SiteQueryResponseContext.Provider>
  )
}

const useSiteQueryResponse = () => useContext(SiteQueryResponseContext)

const useSiteQueryResponseData = () => {
  const {response} = useSiteQueryResponse()
  if (!response) {
    return {
      pages: [],
    }
  }

  return response || {}
}

const useSiteQueryResponseHasMoreData = () => {
  const {response} = useSiteQueryResponse()
  if (!response) {
    return false
  }

  if (response.pages) {
    const pageCount = response.pages.length
    return response.pages[pageCount - 1].data?.LastEvaluatedKey ? true : false
  } else {
    return false
  }
}

const useSiteQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useSiteQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useSiteQueryResponseLoading = (): boolean => {
  const {isLoading} = useSiteQueryResponse()
  return isLoading
}

const useSiteQueryResponseFetchingNextPage = (): boolean => {
  const {isFetchingNextPage} = useSiteQueryResponse()
  return isFetchingNextPage
}

const useSiteQueryResponseFetchNextPage = (): (() => void) => {
  const {fetchNextPage} = useSiteQueryResponse()
  return fetchNextPage
}

export {
  SiteQueryResponseProvider,
  useSiteQueryResponse,
  useSiteQueryResponseData,
  useSiteQueryResponsePagination,
  useSiteQueryResponseLoading,
  useSiteQueryResponseFetchNextPage,
  useSiteQueryResponseFetchingNextPage,
  useSiteQueryResponseHasMoreData,
}
