import {UserModel} from '../../../../auth'
import {
  print,
  create,
  getWaybillsBySiteIdRequest,
  getWaybillsRequest,
  getWaybillsByCompanyIdRequest,
} from './WaybillHelpers.amplify'
import {Waybill, WaybillQueryResponse} from './_models'

export const getWaybillsBySiteId = async (
  siteId: string,
  query?: string,
  currentUser?: UserModel,
  pageParam?: any
): Promise<WaybillQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(
        pageParam.pk
      )}&last_evaluated_key_sk=${encodeURIComponent(
        pageParam.sk
      )}&last_evaluated_key_gsi1pk=${encodeURIComponent(
        pageParam.gsi1pk
      )}&last_evaluated_key_gsi1sk=${encodeURIComponent(pageParam.gsi1sk)}`
    }

    return getWaybillsBySiteIdRequest(currentUser, siteId, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export const getWaybillsByCompanyId = async (
  companyId: string,
  query?: string,
  currentUser?: UserModel,
  pageParam?: any
): Promise<WaybillQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(
        pageParam.pk
      )}&last_evaluated_key_sk=${encodeURIComponent(
        pageParam.sk
      )}&last_evaluated_key_gsi3pk=${encodeURIComponent(
        pageParam.gsi3pk
      )}&last_evaluated_key_gsi3sk=${encodeURIComponent(pageParam.gsi3sk)}`
    }

    return getWaybillsByCompanyIdRequest(currentUser, companyId, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export const getWaybills = async (
  query?: string,
  currentUser?: UserModel,
  pageParam?: any
): Promise<WaybillQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(
        pageParam.pk
      )}&last_evaluated_key_sk=${encodeURIComponent(pageParam.sk)}`
    }

    if (pageParam && pageParam.gsi1pk && pageParam.gsi1sk) {
      query += `&last_evaluated_key_gsi1pk=${encodeURIComponent(
        pageParam.gsi1pk
      )}&last_evaluated_key_gsi1sk=${encodeURIComponent(pageParam.gsi1sk)}`
    }

    if (pageParam && pageParam.gsi2pk && pageParam.gsi2sk) {
      query += `&last_evaluated_key_gsi2pk=${encodeURIComponent(
        pageParam.gsi2pk
      )}&last_evaluated_key_gsi2sk=${encodeURIComponent(pageParam.gsi2sk)}`
    }

    return getWaybillsRequest(currentUser, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export const createWaybill = (
  currentUser: UserModel | undefined,
  siteId: string,
  waybill: Waybill
): Promise<Waybill | undefined> => {
  if (currentUser) {
    return create(currentUser, siteId, waybill).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export const printWaybill = (
  currentUser: UserModel | undefined,
  waybills: Waybill[],
  waybillPrintCount: number,
  labelPrintCount: number
): Promise<Waybill | undefined> => {
  if (currentUser) {
    return print(currentUser, waybills, waybillPrintCount, labelPrintCount).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}
