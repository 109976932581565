import {ID} from '../../../../../../_metronic/helpers'
import {UserModel} from '../../../../auth'
import {Batch, Site, SiteQueryResponse} from './_models'
import {
  getSitesByCompanyId as getSitesByCompanyIdRequest,
  get,
  create,
  del,
  update,
  addSitesToBatch as addSitesToBatchRequest,
  removeSitesFromBatch as removeSitesFromBatchRequest,
  getSitesInBatch as getSitesInBatchRequest,
  processBatch as processBatchRequest,
} from './SiteHelpers.amplify'

const getSitesByCompanyId = async (
  companyId: string,
  query?: string,
  currentUser?: UserModel,
  pageParam?: any
): Promise<SiteQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(
        pageParam.pk
      )}&last_evaluated_key_sk=${encodeURIComponent(
        pageParam.sk
      )}&last_evaluated_key_gsi1pk=${encodeURIComponent(
        pageParam.gsi1pk
      )}&last_evaluated_key_gsi1sk=${encodeURIComponent(pageParam.gsi1sk)}`
    }

    return getSitesByCompanyIdRequest(currentUser, companyId, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const getSitesInBatch = async (
  query?: string,
  currentUser?: UserModel,
  pageParam?: any
): Promise<SiteQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(
        pageParam.pk
      )}&last_evaluated_key_sk=${encodeURIComponent(
        pageParam.sk
      )}&last_evaluated_key_gsi4pk=${encodeURIComponent(
        pageParam.gsi4pk
      )}&last_evaluated_key_gsi4sk=${encodeURIComponent(pageParam.gsi4sk)}`
    }
    return getSitesInBatchRequest(currentUser, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const updateSite = (currentUser: UserModel | undefined, site: Site): Promise<Site | undefined> => {
  if (currentUser) {
    return update(currentUser, site).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const getSiteById = (id: ID, currentUser: UserModel | undefined): Promise<Site | undefined> => {
  if (currentUser && id) {
    return get(id, currentUser).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const createSite = (
  currentUser: UserModel | undefined,
  companyId: string,
  site: Site
): Promise<Site | undefined> => {
  if (currentUser) {
    return create(currentUser, companyId, site).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const processBatch = (
  currentUser: UserModel | undefined,
  batch: Batch
): Promise<Site | undefined> => {
  if (currentUser) {
    return processBatchRequest(currentUser, batch).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const deleteSite = (currentUser: UserModel | undefined, siteId: ID): Promise<Site | undefined> => {
  if (currentUser) {
    return del(currentUser, siteId).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const addSitesToBatch = (
  currentUser: UserModel | undefined,
  sites: ID[]
): Promise<Site | undefined> => {
  if (currentUser) {
    return addSitesToBatchRequest(currentUser, sites).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const removeSitesFromBatch = (
  currentUser: UserModel | undefined,
  sites: ID[]
): Promise<Site | undefined> => {
  if (currentUser) {
    return removeSitesFromBatchRequest(currentUser, sites).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export {
  getSitesByCompanyId,
  getSiteById,
  createSite,
  deleteSite,
  updateSite,
  addSitesToBatch,
  removeSitesFromBatch,
  getSitesInBatch,
  processBatch,
}
