import React from 'react'
import {SitesListSearchComponent} from './SitesListSearchComponent'
import {SitesListToolbar} from './SitesListToolbar'
import {SitesListGrouping} from './SitesListGrouping'
import {useSiteListView} from '../../core/SiteListViewProvider'

export const SitesListHeader = () => {
  const {selected} = useSiteListView()

  return (
    <div className='card-header border-0 pt-6'>
      <SitesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className=''>
        {/* begin::Group actions */}
        {selected.length > 0 ? <SitesListGrouping /> : <SitesListToolbar />}

        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}
